body.saved-vouchr {

    main {
        h1.main-title {
            text-align: center;
            margin-top: 0px;
            font-size: 54px;
        }

        section.main-content {
            padding-top: 45px;

            .view-all {
                padding: 0 10px;
                border-left: 1px solid @vouchr-color-dark-grey;
                cursor: pointer;
            }
        }
    }

    .vouchr-list-container {

        .vouchr-container {

            // .btn-view-vouchr {
            //     right: 79px;
            //     width: 278px;

            //     @media(max-width: @screen-md-max) {
            //         width: 124px;
            //         // height: 54px;
            //         // padding-top: 16px;
            //     }

            //     @media(max-width: @screen-sm-max) {
            //         width: 148px;
            //     }

            //     @media(max-width: @screen-xs-max) {
            //         width: 167px;
            //         right: 59px;
            //     }

            //     .view-offer-vouchr-text {

            //         @media(max-width: @screen-md-max) {
            //             display: none;
            //         }
            //     }
            // }

            // .btn-delete-vouchr {
            //     position: absolute;
            //     bottom: 10px;
            //     right: 20px;
            //     width: 50px;
            //     display: block;

            //     @media(max-width: @screen-xs-max) {
            //         bottom: 0px;
            //         right: 0px;
            //     }
            // }
        }
    }
}