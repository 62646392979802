.owl-action-button() {
    display: block;
    border: 3px solid @vouchr-color-babypoo;
    background-color: @vouchr-color-orange;
    color: white;
    font-size: 20px;
    font-weight: 800;
    border-radius: 50%;
    bottom: 110px;
    position: absolute;
    padding: 6px;
    cursor: pointer;

    &:hover {
        color: @vouchr-color-babypoo;
        border-color: @vouchr-color-babypoo;
    }
}

.owl-dots-span() {
    display: block;
    border: 3px solid @vouchr-color-orange;
    color: @vouchr-color-orange;
    position: absolute;
    bottom: -20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;

    &:hover {
        background-color: @vouchr-color-orange;
    }
}

@carousel-width: 944px;
@carousel-width-md: @carousel-width  - 0px;
@carousel-width-sm: @carousel-width-md  - 240px;
@carousel-width-xs: @carousel-width-sm - 475px;

.owl-carousel-container {
    width: @carousel-width;
    position: absolute;
    left: 50%;
    margin-left: (@carousel-width / 2) * -1;
    margin-top: 71px;
}

.owl-carousel.vouchrs-carousel {
    position: relative;

    .owl-item {
        text-align: center;

        .coupon {
            display: inline-block;
            margin: 0px;

            img.pocketfront {
                width: initial;
            }

            img.addbtn {
                width: initial;
            }
        }
    }

    .owl-prev {
        .owl-action-button();
        left: -90px;
    }

    .owl-next {
        .owl-action-button();
        right: -90px;
    }

    .owl-dots {
        margin-top: 20px;
        text-align: center;

        .owl-dot {
            display: inline-block;
            margin: 0px 15px;

            span {
                .owl-dots-span();
            }

            &.active {

                span {
                    background-color: @vouchr-color-orange;
                }
            }
        }
    }
}

@media(max-width: @screen-md-max) {
    .owl-carousel-container {
        width: @carousel-width-md;
        margin-left: (@carousel-width-md / 2) * -1;
    }

    .owl-carousel.vouchrs-carousel {

        .owl-prev {
            left: 10px;
            bottom: -30px;
        }

        .owl-next {
            right: 10px;
            bottom: -30px;
        }
    }
}

@media(max-width: @screen-sm-max) {
    .owl-carousel-container {
            width: @carousel-width-sm;
            margin-left: (@carousel-width-sm / 2) * -1;
    }
}

@media(max-width: @screen-xs-max) {
    .owl-carousel-container {
        width: @carousel-width-xs;
        margin-left: (@carousel-width-xs / 2) * -1;
    }

    .owl-carousel.vouchrs-carousel {

        .owl-prev {
            font-size: 14px;
            left: 0px;
            bottom: -27px;
        }

        .owl-next {
            font-size: 14px;
            right: 0px;
            bottom: -27px;
        }

        .owl-dots {
            margin-left: -16px;

            .owl-dot {
                margin: 0px 12px;
            }
        }
    }
}
