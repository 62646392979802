body.home {
    section.hero {
        height: 450px;
        background-color: #efefef;
        background-image: url('../images/hero_background_trimmed.jpg');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .home-page-title {
        font-size: 46px;
        margin-top: 0;

        @media(max-width: @screen-xs-max) {
            font-size: 22px;
            text-align: center;
        }
    }

    main {
        h1.main-title {
            text-align: center;
            margin-top: 0px;
            font-size: 54px;
        }

        h2.sub-title {
            font-size: 22.5px;
            text-align: center;
            margin-top: 31px;
        }

        p.description {
            text-align: center;
            font-family: 'vagroundedlight';
            font-weight: 200;
            font-size: 17.5px;

            a {
                color: @vouchr-color-orange;
            }
        }

        section.main-content {
            padding-top: 45px;
        }
    }
}