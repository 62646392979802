body.vouchr {
    @vouchr-content-margin: 40px;

    .vouchr-content-container {
        background-color: white;
        padding: 50px 25px;
        margin-bottom: @vouchr-content-margin;
        min-height: @coupon-height + 100px;
        position: relative;

        .shadow();

        .coupon {
            margin: 0px;
        }

        .vouchr-content {
            overflow: hidden;
            padding-left: 50px;

            h1 {
                margin-top: 0px;
                font-size: 35px;
            }

            h2 {
                font-size: 22px;
                margin-top: 10px;
            }
        }

        .cta-container {
            list-style-type: none;
            position: absolute;
            right: 20px;
            bottom: 50px;

            li {
                float: left;

                &:not(:first-child) {
                    margin-left: 5px;
                }

                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }

    .brand-container {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 0;
        float: left;
        height: 251px;

        .middle-helper-brand {
            display: inline-block;
            height: 190px;
            vertical-align: middle;
        }
    }

    .brand-description-container {
        width: 600px;
        margin-left: 20px;
        float: right;
        min-height: 251px;
        background-color: white;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-top: 10px;

        .shadow();

        h1 {
            margin: 0px;
            font-size: 38px;
        }

        h2 {
            font-size: 22px;
            margin: 12px 0px 13px 0px;
        }

        .sub-title {
            margin-top: 10px;
            min-height: 52px;
        }

        .description {
            font-size: 17px;
            line-height: 1.2;
            margin: 0 0 8px;
            min-height: 59px;
        }
    }

    .followers-container {
        clear: both;
        background-color: white;
        border-top: 1px solid @vouchr-color-grey-border;

        .brand-column();
        // .shadow();

        margin: 0;
        width: 100%;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        &.expired-vouchr-followers {
            border-top: 0;

            .followers {
                padding: 0;

                .btn {
                    &.btn-follow {
                        border-radius: 5px;
                        float: right;
                        width: 100%;
                    }
                }
            }
        }

        .followers {
            width: 100%;
            margin: 0;
            text-align: left;
            padding: 8px 20px;

            p {
                margin: 0px;
                font-weight: 800;
                line-height: 1;
                color: @vouchr-color-followers;
                font-size: 18px;
            }

            .line-1 {
                font-size: 16px;
                display: inline-block;
            }

            .line-2 {
                font-size: 18px;
                display: inline-block;
            }

            .line-3 {
                font-size: 16px;
                padding-top: 15px;
                display: inline-block;
            }

            .btn {
                &.btn-follow {
                    border-radius: 5px;
                    float: right;
                    width: 224px;
                }
            }
        }
    }

    .vouchr-list {
        overflow: hidden;
    }

    .list-filter-form {
        .brand-select-box {
            // display: none;
        }
    }
}

@media(max-width: @screen-md-max) {
    body.vouchr {
        .vouchr-content-container {
            .vouchr-content {
                h4 {
                    margin-top: -5px;
                    font-size: 16px;
                }
            }
        }

        .brand-description-container {
            width: 450px;
        }
        .followers-container {
            .followers {
                .btn {
                    &.btn-follow {
                        width: 119px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media(max-width: @screen-sm-max) {
    body.vouchr {
        .brand-description-container {
            width: 473px;
        }
        .followers-container {
            .followers {
                .btn {
                    &.btn-follow {
                        width: 119px;
                        font-size: 18px;
                    }
                }
            }
        }

        .list-filter-form {
            .category-select-box,
            .location-select-box {
                // width: 50%;
            }
        }
    }
}

@media(max-width: @screen-xs-max) {
    body.vouchr {
        
        .vouchr-content-container {

            .coupon {
                float: none !important;
                display: block;
                margin: 0 auto;
            }

            .vouchr-content {
                display: block;
                text-align: center;
                margin: 10px 0;
                padding: 0;

                h4 {
                    margin-top: 10px;
                    font-size: 18px;
                }
            }

            .cta-container {
                position: initial;
                .clearfix();
                margin-top: 10px;

                li {
                    width: 100%;
                    margin-bottom: 10px;

                    &:not(:first-child) {
                        margin-left: 0px;
                    }

                    &:not(:last-child) {
                        margin-right: 0px;
                    }

                    a, button {
                        width: 100%;
                    }
                }

            }
        }

        .brand-container {
            .middle-helper-brand {
                height: 165px;
            }
        }

        .vouchr-list-container {
            background-color: transparent;
            padding: 0;
            margin: 0;

            .brand-followers-container {
                // height: 166px;
                margin: 20px auto;
                // background-color: white;
                // .shadow();
                .round-box();
            }

            .brand-container {
                width: 100%;
                height: 212px;
                margin: 0;
                float: none;
            }

            .brand-description-container {
                width: 100%;
                margin: 10px 0 0 0;
                height: initial;
                background-color: white;
            }

            .followers-container {
                width: 100%;

                .followers {
                    border: 0;
                    border-left: 0px solid #e1e1e1;
                    margin: 0;
                    width: 100%;
                    // padding: 12px 3px;
                    text-align: left;
                    font-weight: 800;
                    color: #919191;

                    .line-3 {
                        padding-top: 10px;
                    }
                }

                .btn {
                    &.btn-follow {
                        width: 100%;
                        border-radius: 5px;
                        margin-top: 20px;
                    }
                }
            }

            .vouchr-list {

                .vouchr-list-container {
                    background-color: #e1e1e1;
                    padding: 10px;
                    margin: 20px 20px;
                }
            }

            .vouchr-list-no-directive {

                .vouchr-list-container {
                    background-color: #e1e1e1;
                    padding: 10px;
                    margin: 20px 20px;
                }
            }
        }

        .list-filter-form {
            .category-select-box,
            .location-select-box {
                width: 100%;
            }
        }
    }
}
