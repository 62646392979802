.modal-backdrop {
    opacity: 0.5;
}

.modal {
    .modal-header {
        padding: 0px 0px 0px 30px;
        background-color: @vouchr-color-orange;
        border-top-left-radius: 0.3em;
        border-top-right-radius: 0.3em;
        color: white;
        height: @col-head-height;

        h1, h2, h3, h4 {
            margin-top: 0;
            margin-bottom: 0;
            line-height: @col-head-height;
            font-size: 18px;
        }

        .close {
            padding-right: 15px;
            padding-left: 15px;
            padding-top: 11px;
            padding-bottom: 13px;
        }
    }

    &.alert, &.confirm {
        p {
            font-size: 16px;
        }
    }

    a {
        color: @vouchr-color-orange;
    }
}

@import 'login-modal.less';
@import 'register-modal.less';
@import 'share-modal.less';