ul,
ol,
dl {
  margin: 0 auto;
  padding: 0;
}
@font-face {
  font-family: 'vagroundedbold';
  src: url('../fonts/18_vag_rounded_bold___07390-webfont.eot');
  src: url('../fonts/18_vag_rounded_bold___07390-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/18_vag_rounded_bold___07390-webfont.woff') format('woff'), url('../fonts/18_vag_rounded_bold___07390-webfont.ttf') format('truetype'), url('../fonts/18_vag_rounded_bold___07390-webfont.svg#vagroundedbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'vagroundedlight';
  src: url('../fonts/18_vag_rounded_light___02390-webfont.eot');
  src: url('../fonts/18_vag_rounded_light___02390-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/18_vag_rounded_light___02390-webfont.woff') format('woff'), url('../fonts/18_vag_rounded_light___02390-webfont.ttf') format('truetype'), url('../fonts/18_vag_rounded_light___02390-webfont.svg#vagroundedlight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'vagrounded';
  src: url('../fonts/18_vag_rounded_light___02390-webfont.eot');
  src: url('../fonts/18_vag_rounded_light___02390-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/18_vag_rounded_light___02390-webfont.woff') format('woff'), url('../fonts/18_vag_rounded_light___02390-webfont.ttf') format('truetype'), url('../fonts/18_vag_rounded_light___02390-webfont.svg#vagroundedlight') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'vagrounded';
  src: url('../fonts/18_vag_rounded_bold___07390-webfont.eot');
  src: url('../fonts/18_vag_rounded_bold___07390-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/18_vag_rounded_bold___07390-webfont.woff') format('woff'), url('../fonts/18_vag_rounded_bold___07390-webfont.ttf') format('truetype'), url('../fonts/18_vag_rounded_bold___07390-webfont.svg#vagroundedbold') format('svg');
  font-weight: 700;
  font-style: normal;
}
.highlight.orange {
  color: #cb5e27 !important;
}
body {
  font-family: 'vagrounded';
  background-color: #313131;
  -webkit-font-smoothing: antialiased;
}
body > svg {
  display: none;
}
body main {
  padding-bottom: 140px;
  padding-top: 40px;
  background-color: #f5f5f5;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 800;
}
svg {
  fill: white;
  width: 25px;
  height: 25px;
}
.bold {
  font-weight: 800;
}
.white {
  color: #ffffff;
}
.dropdown .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow: scroll;
}
.dropdown .dropdown-menu a {
  cursor: pointer;
}
.middle-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.middle-helper-brand {
  display: inline-block;
  height: 165px;
  vertical-align: middle;
}
.no-padding {
  padding: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.show-loading {
  display: none;
  background-image: url(../images/loading_grey_white.gif);
  background-position: center center;
  background-repeat: no-repeat;
  width: 42px;
  height: 42px;
  padding: 0;
  background-color: transparent;
}
.show-loading.white-on-grey {
  background-image: url(../images/loading_white_grey.gif);
  background-color: #4e4d4d;
}
.show-loading.white-on-orange {
  background-image: url(../images/loading_white_orange.gif);
}
.show-loading.white-on-yellow {
  background-image: url(../images/loading_white_yellow.gif);
}
.loading .hide-loading {
  display: none;
}
.loading .show-loading {
  display: inline-block;
}
.col-head {
  background-color: #cb5e27;
  color: white;
  height: 45px;
}
.col-head h1,
.col-head h2,
.col-head h3,
.col-head h4 {
  margin: 0px;
  line-height: 45px;
  padding-left: 20px;
  font-size: 18px;
  position: relative;
}
@media (max-width: 767px) {
  .col-head h1,
  .col-head h2,
  .col-head h3,
  .col-head h4 {
    font-size: 16px;
  }
}
.col-head h1.icon-padded,
.col-head h2.icon-padded,
.col-head h3.icon-padded,
.col-head h4.icon-padded {
  padding-left: 52px;
}
@media (max-width: 767px) {
  .col-head h1.icon-padded,
  .col-head h2.icon-padded,
  .col-head h3.icon-padded,
  .col-head h4.icon-padded {
    padding-left: 42px;
  }
}
.col-head .icon {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 25px;
  height: 25px;
}
@media (max-width: 767px) {
  .col-head .icon {
    left: 12px;
  }
}
.col-head.round-top {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.col-head.round-bottom {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.shadow-box {
  padding: 20px;
  background-color: white;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  border-radius: 5px;
}
header {
  height: 60px;
  background-color: #cb5e27;
}
header .col-md-12 {
  position: initial;
}
header .dropdown.main-dropdown {
  position: initial;
}
header .dropdown.main-dropdown > .dropdown-toggle {
  margin-top: 7px;
  height: 44px;
  font-size: 16px;
}
header .dropdown.main-dropdown > .dropdown-toggle > span {
  margin-left: 15px;
}
header .dropdown.main-dropdown ul.dropdown-menu {
  list-style-type: none;
  width: 300px;
  padding: 5px 0px;
  margin-top: 8px;
  background-color: white;
  position: absolute;
  top: 51px;
  margin-left: 50%;
  left: 261px;
  border-radius: 7px;
}
@media (max-width: 1199px) {
  header .dropdown.main-dropdown ul.dropdown-menu {
    left: 161px;
  }
}
@media (max-width: 991px) {
  header .dropdown.main-dropdown ul.dropdown-menu {
    left: 51px;
  }
}
@media (max-width: 767px) {
  header .dropdown.main-dropdown ul.dropdown-menu {
    left: 0px;
    width: calc(100% + 1px);
    margin-left: 0px;
  }
}
header .dropdown.main-dropdown ul.dropdown-menu menuitem {
  display: list-item;
  height: 44px;
  padding: 0px 20px;
}
header .dropdown.main-dropdown ul.dropdown-menu menuitem:not(:last-child) a {
  border-bottom: 1px solid #ccc;
}
header .dropdown.main-dropdown ul.dropdown-menu menuitem:hover {
  background-color: #eee;
}
header .dropdown.main-dropdown ul.dropdown-menu menuitem a {
  display: block;
  height: 44px;
  line-height: 44px;
  width: 100%;
  position: relative;
  color: black;
  font-size: 18px;
  font-weight: 400;
}
header .dropdown.main-dropdown ul.dropdown-menu menuitem a .icon {
  width: 20px;
  height: 20px;
  fill: #cb5e27;
  position: absolute;
  right: 20px;
  top: 10px;
}
header .dropdown.main-dropdown ul.dropdown-menu menuitem a .icon use > svg {
  fill: #cb5e27;
}
header .dropdown.main-dropdown.open .avatar-icon {
  margin-left: -49px;
}
header .dropdown.main-dropdown.open nav .dropdown-toggle.btn.btn-vouchr .icon.avatar-icon {
  margin-left: -50px;
}
header .logo {
  height: 100%;
  padding-top: 10px;
  display: inline-block;
  float: left;
}
header .btn-login {
  margin-top: 7px;
}
header nav {
  height: 60px;
  line-height: 60px;
  z-index: 9001;
  float: left;
  padding-left: 50px;
  width: calc(100% - 125px);
}
@media (max-width: 991px) {
  header nav {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  header nav {
    padding-left: 0px;
  }
}
header nav .dropdown-toggle.btn.btn-vouchr .icon {
  margin-top: -2px;
  margin-left: 1px;
  margin-right: 0px;
}
header nav menuitem {
  padding: 0px 10px;
  font-size: 18px;
}
@media (max-width: 991px) {
  header nav menuitem {
    padding: 0px 5px;
  }
  header nav menuitem.menu-item-or {
    padding: 0px 0px;
  }
}
header nav menuitem.extra-pad {
  padding: 0px 17px 0px 15px;
}
header nav menuitem:first-child {
  padding-right: 0px;
}
header nav a {
  color: white;
  font-weight: 800;
}
header nav a:hover {
  color: white;
}
header nav .icon-hide-container {
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 10px;
}
header nav .icon-hide-container .icon {
  width: 20px;
  height: 20px;
}
.search-form .search-toggle {
  height: 44px;
  margin-top: 7px;
  line-height: 26px;
  font-size: 18px;
}
.search-form .search-toggle .search-icon-container {
  float: left;
  width: 25px;
  height: 25px;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .search-form .search-toggle .search-icon-container {
    margin-right: 0px;
    margin-top: -2px;
  }
}
.search-form .search-toggle .search-icon-container .icon {
  float: left;
  clear: both;
}
.search-form .search-toggle span {
  vertical-align: middle;
}
.search-form .search-toggle span.search-text {
  display: inline;
}
.search-form .search-toggle span.search-text-close {
  display: none;
}
.search-form.dropdown {
  position: initial;
}
.search-form form.dropdown-menu {
  width: 400px;
  padding: 0px;
  margin-top: 8px;
  position: absolute;
  top: 51px;
  margin-left: 50%;
  left: 161px;
  border-radius: 7px;
}
@media (max-width: 1199px) {
  .search-form form.dropdown-menu {
    left: 61px;
  }
}
@media (max-width: 991px) {
  .search-form form.dropdown-menu {
    left: -49px;
  }
}
@media (max-width: 767px) {
  .search-form form.dropdown-menu {
    left: 0px;
    margin-left: 0px;
    width: calc(100% + 1px);
  }
}
.search-form form.dropdown-menu.results-open .form-group {
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.search-form form.dropdown-menu.results-open .form-group .input-group button {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.search-form form.dropdown-menu .form-group {
  width: 100%;
  margin-bottom: 0px;
}
.search-form form.dropdown-menu .form-group .input-group {
  width: 100%;
}
.search-form form.dropdown-menu .form-group .input-group .form-control:focus {
  z-index: initial;
}
.search-form form.dropdown-menu .form-group .input-group input {
  border: 0;
  outline: none;
  box-shadow: none;
}
.search-form form.dropdown-menu .form-group .input-group button {
  height: 44px;
  width: 49px;
  background-color: #d7a602;
  padding: 0px;
}
.search-form .results-list {
  list-style-type: none;
  max-height: calc(100vh - 110px);
  overflow-y: scroll;
  padding: 3px 0px 10px;
}
.search-form .results-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: initial;
  padding: 0px 20px;
}
.search-form .results-list li:hover {
  background-color: #eee;
}
.search-form .results-list li a {
  padding: 5px 0px;
  color: black;
  display: block;
  font-weight: 400;
  font-size: 18px;
  height: 44px;
  line-height: 38px;
}
.search-form .results-list li:not(:last-child) a {
  border-bottom: 1px solid #ddd;
}
.search-form.open .search-toggle {
  background-color: #d7a602;
}
.search-form.open .search-toggle .search-icon-container .search-icon {
  margin-top: -25px;
}
.search-form.open .search-toggle span.search-text {
  display: none;
}
.search-form.open .search-toggle span.search-text-close {
  display: inline;
}
body .breadcrumb-container {
  background-color: #f5f5f5;
}
body .breadcrumb-container .breadcrumb {
  margin-bottom: 0;
  padding-top: 20px;
  border-radius: 0px;
}
body .breadcrumb-container .breadcrumb a {
  font-size: 18px;
  color: #333;
  font-weight: 800;
  cursor: pointer;
  text-decoration: none;
}
body .breadcrumb-container .breadcrumb li {
  color: #cb5e27 !important;
  font-size: 18px;
  font-weight: 800;
}
@media (max-width: 767px) {
  body .breadcrumb-container .breadcrumb {
    display: none;
  }
  body .breadcrumb-container .breadcrumb a {
    font-size: 14px;
  }
  body .breadcrumb-container .breadcrumb li {
    font-size: 14px;
  }
}
footer {
  background-color: #313131;
  padding-top: 30px;
  padding-bottom: 20px;
  color: white;
}
footer a {
  color: white;
}
footer a:hover {
  color: white;
  text-decoration: underline;
}
footer .row {
  margin-bottom: 30px;
}
footer ul {
  list-style-type: none;
}
footer ul li {
  float: left;
  text-align: left;
}
footer ul li.col-md-2,
footer ul li .col-sm-3 .col-xs-6 {
  padding: 2px 0px 2px 0px;
}
@media (max-width: 767px) {
  footer ul li {
    text-align: center;
  }
}
footer h4 {
  marign-top: 0px;
}
@media (max-width: 767px) {
  footer h4 {
    text-align: center;
  }
}
.coupon {
  background: transparent url('../images/vouchr_sleeve.png') no-repeat left top;
  background-size: 100%;
  width: 226px;
  height: 225px;
  display: inline-block;
  margin: 4px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.coupon .content {
  padding-top: 10px;
  text-align: left;
  position: relative;
}
.coupon .content .info {
  width: 206px;
  height: 98px;
  margin: 0px auto;
  padding-bottom: 10px;
  border-radius: 0px 0px 7px 7px;
}
.coupon .content .info h1,
.coupon .content .info h2,
.coupon .content .info h3,
.coupon .content .info h4 {
  color: #FFF;
  font-weight: normal;
  margin: 0px;
  text-shadow: 0px 1px 0px #932D19;
  text-transform: uppercase;
}
.coupon .content .info h1 {
  font-size: 14px;
  padding: 6px 8px 0px 8px;
  line-height: 100%;
}
.coupon .content .info h2 {
  font-size: 11.5px;
  padding: 4px 8px 0px 8px;
}
.coupon .content .info h3 {
  font-family: Arial;
  font-size: 9.5px;
  font-weight: bold;
  padding: 0px 8px 0px 8px;
}
.coupon .content .info h3.discount {
  font-size: 16px;
  margin: 3px 0;
}
.coupon .content .info h4 {
  font-family: Arial;
  font-size: 8.5px;
  padding: 1px 8px 0px 8px;
  text-transform: none;
  color: #ffffff;
  width: 208px;
  line-height: 100%;
}
.coupon .content .addbtn {
  position: absolute;
  right: 8px;
  top: 140px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
}
.coupon .content .offers {
  position: absolute;
  margin-top: 178px;
  margin-left: 188px;
  width: 50px;
  text-align: center;
}
.coupon .content .offers h1,
.coupon .content .offers h2 {
  margin: 0;
  color: #FFF;
  font-weight: normal;
}
.coupon .pocketfront {
  position: absolute;
  margin: 117px 5px 0px 5px;
}
.coupon .proimage {
  width: 207px;
  height: 115px;
  margin: 0px auto;
  border-radius: 15px 15px 0px 0px;
}
.coupon .proimage img {
  border-radius: 10px 10px 0px 0px;
  width: 207px;
  height: 113px;
}
.vouchr-list {
  margin-top: 20px;
}
.vouchr-list-no-directive {
  margin-top: 20px;
}
.vouchr-list-container {
  margin-bottom: 20px;
}
.vouchr-list-container .brand-container {
  height: 225px;
  background-color: white;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  border-radius: 5px;
  width: 227px;
  margin-left: 20px;
  float: right;
}
.vouchr-list-container .brand-container .logo-container {
  height: 160px;
  line-height: 160px;
  text-align: center;
  vertical-align: middle;
}
.vouchr-list-container .brand-container .logo-container img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 227px;
  max-height: 150px;
}
.vouchr-list-container .brand-container .followers {
  text-align: center;
  font-weight: 800;
  color: #333333;
  border-top: 1px solid #e1e1e1;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
  width: 100%;
  float: left;
  font-size: 18px;
}
.vouchr-list-container .vouchr-container {
  background-color: white;
  overflow: hidden;
  width: auto;
  position: relative;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.vouchr-list-container .vouchr-container .coupon {
  margin-left: 0px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  float: left;
}
.vouchr-list-container .vouchr-container .vouchr-list-content {
  overflow: hidden;
  padding-right: 20px;
}
.vouchr-list-container .vouchr-container .vouchr-list-content .heading {
  font-weight: 800;
  color: #cb5e27;
  font-size: 22px;
  margin-bottom: 0px;
}
.vouchr-list-container .vouchr-container .vouchr-list-content .sub-heading {
  font-weight: 800;
  color: black;
  font-size: 16px;
  margin-top: 0px;
}
.vouchr-list-container .vouchr-container .vouchr-list-content .vouchr-description {
  font-family: 'vagroundedlight';
  font-weight: 200;
  text-align: left;
  font-size: 16px;
}
@media (max-width: 1199px) {
  .vouchr-list-container .vouchr-container .vouchr-list-content .vouchr-description {
    font-size: 14px;
  }
}
.vouchr-list-container .vouchr-container .btn-view-vouchr {
  position: absolute;
  width: 224px;
  height: 44px;
  bottom: 10px;
  right: 20px;
  padding: 10px 10px;
}
@media (max-width: 1199px) {
  .vouchr-list-container .vouchr-container .btn-view-vouchr {
    width: 184px;
  }
}
@media (max-width: 991px) {
  .vouchr-list-container .vouchr-container .btn-view-vouchr {
    width: 208px;
  }
}
.vouchr-list-container .vouchr-container .btn-view-vouchr .view-offer-vouchr-text {
  display: inline;
}
.vouchr-list-container .vouchr-container .btn-delete-vouchr {
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 50px;
  display: none;
}
@media (max-width: 767px) {
  .coupon .content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .vouchr-list {
    width: 100%;
    margin: 20px auto;
  }
  .vouchr-list-no-directive {
    width: 100%;
    margin: 20px auto;
  }
  .vouchr-list-container {
    background-color: #e1e1e1;
    padding: 10px;
    margin: 20px 20px;
    border-radius: 5px;
  }
  .vouchr-list-container .brand-container {
    height: 133px;
    width: 226px;
    float: none;
    margin: 0px auto 10px;
  }
  .vouchr-list-container .brand-container .logo-container {
    width: 100%;
    height: 88px;
    float: left;
  }
  .vouchr-list-container .brand-container .logo-container img {
    height: 58px;
  }
  .vouchr-list-container .brand-container .followers {
    border: 0;
    border-top: 1px solid #e1e1e1;
    margin: 0;
    width: 100%;
    float: left;
    padding: 12px 3px;
  }
  .vouchr-list-container .brand-container .followers.vouchr-num-items {
    border-top: 1px solid #e1e1e1;
  }
  .vouchr-list-container .vouchr-container {
    width: 226px;
    background-color: transparent;
    margin: 0 auto;
  }
  .vouchr-list-container .vouchr-container .coupon {
    margin-right: 0;
    height: 280px;
  }
  .vouchr-list-container .vouchr-container .btn-view-vouchr {
    right: 0px;
    width: 224px;
    bottom: 0;
  }
}
.owl-carousel-container {
  width: 944px;
  position: absolute;
  left: 50%;
  margin-left: -472px;
  margin-top: 71px;
}
.owl-carousel.vouchrs-carousel {
  position: relative;
}
.owl-carousel.vouchrs-carousel .owl-item {
  text-align: center;
}
.owl-carousel.vouchrs-carousel .owl-item .coupon {
  display: inline-block;
  margin: 0px;
}
.owl-carousel.vouchrs-carousel .owl-item .coupon img.pocketfront {
  width: initial;
}
.owl-carousel.vouchrs-carousel .owl-item .coupon img.addbtn {
  width: initial;
}
.owl-carousel.vouchrs-carousel .owl-prev {
  display: block;
  border: 3px solid #d7a602;
  background-color: #cb5e27;
  color: white;
  font-size: 20px;
  font-weight: 800;
  border-radius: 50%;
  bottom: 110px;
  position: absolute;
  padding: 6px;
  cursor: pointer;
  left: -90px;
}
.owl-carousel.vouchrs-carousel .owl-prev:hover {
  color: #d7a602;
  border-color: #d7a602;
}
.owl-carousel.vouchrs-carousel .owl-next {
  display: block;
  border: 3px solid #d7a602;
  background-color: #cb5e27;
  color: white;
  font-size: 20px;
  font-weight: 800;
  border-radius: 50%;
  bottom: 110px;
  position: absolute;
  padding: 6px;
  cursor: pointer;
  right: -90px;
}
.owl-carousel.vouchrs-carousel .owl-next:hover {
  color: #d7a602;
  border-color: #d7a602;
}
.owl-carousel.vouchrs-carousel .owl-dots {
  margin-top: 20px;
  text-align: center;
}
.owl-carousel.vouchrs-carousel .owl-dots .owl-dot {
  display: inline-block;
  margin: 0px 15px;
}
.owl-carousel.vouchrs-carousel .owl-dots .owl-dot span {
  display: block;
  border: 3px solid #cb5e27;
  color: #cb5e27;
  position: absolute;
  bottom: -20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}
.owl-carousel.vouchrs-carousel .owl-dots .owl-dot span:hover {
  background-color: #cb5e27;
}
.owl-carousel.vouchrs-carousel .owl-dots .owl-dot.active span {
  background-color: #cb5e27;
}
@media (max-width: 1199px) {
  .owl-carousel-container {
    width: 944px;
    margin-left: -472px;
  }
  .owl-carousel.vouchrs-carousel .owl-prev {
    left: 10px;
    bottom: -30px;
  }
  .owl-carousel.vouchrs-carousel .owl-next {
    right: 10px;
    bottom: -30px;
  }
}
@media (max-width: 991px) {
  .owl-carousel-container {
    width: 704px;
    margin-left: -352px;
  }
}
@media (max-width: 767px) {
  .owl-carousel-container {
    width: 229px;
    margin-left: -114.5px;
  }
  .owl-carousel.vouchrs-carousel .owl-prev {
    font-size: 14px;
    left: 0px;
    bottom: -27px;
  }
  .owl-carousel.vouchrs-carousel .owl-next {
    font-size: 14px;
    right: 0px;
    bottom: -27px;
  }
  .owl-carousel.vouchrs-carousel .owl-dots {
    margin-left: -16px;
  }
  .owl-carousel.vouchrs-carousel .owl-dots .owl-dot {
    margin: 0px 12px;
  }
}
.btn.light {
  background-color: #d7a602;
  color: white;
}
.btn.grey {
  background-color: #4e4d4d;
  color: white;
}
.btn.light-grey {
  background-color: #747777;
  color: white;
}
.btn.orange {
  background-color: #cb5e27;
  color: white;
}
.btn.btn-vouchr {
  line-height: 44px;
  height: 44px;
  text-transform: uppercase;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 800;
  text-align: center;
  vertical-align: middle;
}
.btn.btn-vouchr img {
  vertical-align: middle;
}
.btn.btn-vouchr span.middle-helper {
  vertical-align: middle;
}
.btn.btn-vouchr.vouchr-cta {
  width: 169px;
}
@media (max-width: 1199px) {
  .btn.btn-vouchr.vouchr-cta {
    width: 119px;
  }
}
@media (max-width: 991px) {
  .btn.btn-vouchr.vouchr-cta {
    width: 129px;
  }
}
.btn.btn-vouchr span.glyphicon,
.btn.btn-vouchr span.icon {
  margin-left: 5px;
}
.btn.btn-vouchr .icon {
  fill: white;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.btn.btn-vouchr .icon use > svg {
  fill: white;
}
.btn.btn-vouchr.btn-login {
  padding: 0px 20px 0px 20px;
  height: 44px;
  position: relative;
  font-size: 18px;
}
.btn.btn-vouchr.btn-login span {
  vertical-align: middle;
}
.btn.btn-vouchr.btn-login svg {
  height: 25px;
  fill: white;
  position: absolute;
  top: 7.5px;
  right: 15px;
}
.btn.btn-vouchr-select {
  width: 100%;
  height: 44px;
  line-height: 44px;
  vertical-align: middle;
  text-align: left;
  background-color: white;
  border: 1px solid #e1e1e1;
  padding: 0px 45px 0px 10px;
  color: black;
}
.btn.btn-vouchr-select .icon {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  margin-top: 8.5px;
  fill: #cb5e27;
}
.btn.btn-vouchr-select .icon use > svg {
  fill: #cb5e27;
}
.btn.btn-search {
  border-radius: 0px;
  background-color: #cb5e27;
  padding: 0px 6px;
  height: 44px;
  width: 44px;
}
.btn.btn-search .icon {
  position: absolute;
  top: 10.5px;
  left: 10.5px;
  width: 21px;
  height: 21px;
}
.btn.btn-follow {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  font-weight: 800;
  height: 44px;
  width: 100%;
  background-color: #4e4d4d;
  color: white;
  padding: 0px;
  line-height: 44px;
  vertical-align: middle;
  font-size: 18px;
}
.btn.btn-follow:after {
  content: 'Follow this brand';
}
@media (max-width: 1199px) {
  .btn.btn-follow:after {
    content: 'Follow';
  }
}
@media (max-width: 767px) {
  .btn.btn-follow:after {
    content: 'Follow';
  }
}
@media (max-width: 767px) {
  .btn.btn-follow {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
}
.btn.btn-follow.following {
  background-color: #cb5e27;
}
.btn.btn-follow.following:after {
  content: 'Following';
}
.btn.btn-follow .icon {
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 5px;
}
.btn.btn-follow.loading {
  background-image: url(../images/loading_grey_white.gif);
  background-position: center center;
  background-repeat: no-repeat;
  width: inherit;
  height: 44px;
  padding: 0;
  background-color: transparent;
}
.btn.btn-follow.loading .icon {
  display: none;
}
.btn.btn-follow.loading.white-on-grey {
  background-image: url(../images/loading_white_grey.gif);
  background-color: #4e4d4d;
}
.btn.btn-follow.loading.white-on-orange {
  background-image: url(../images/loading_white_orange.gif);
}
.btn.btn-follow.loading.white-on-yellow {
  background-image: url(../images/loading_white_yellow.gif);
}
.btn.btn-follow.loading:after {
  content: '';
}
.btn.btn-follow.loading.following:after {
  content: '';
}
.btn.btn-view-vouchr {
  text-align: center;
  background-color: #d7a602;
  width: 100%;
  color: white;
  font-weight: 800;
}
.btn.btn-delete-vouchr {
  text-align: center;
  background-color: #cb5e27;
  color: white;
  font-weight: 800;
  height: 44px;
}
.btn.btn-delete-vouchr .icon {
  height: 30px;
  width: 25px;
}
.btn.btn-vouchr-load-more {
  height: 44px;
  width: 100%;
  font-weight: 800;
  font-size: 17px;
  line-height: 44px;
  padding: 0px;
  background-color: white;
  color: #cb5e27;
  text-align: center;
  cursor: pointer;
  border-radius: 7px;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
}
@media (max-width: 767px) {
  .btn.btn-vouchr-load-more {
    width: 248px;
    display: block;
    margin: 0 auto;
  }
}
.btn.btn-login-cta {
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 216px;
  height: 78px;
  line-height: 78px;
  padding: 0px;
  color: black;
  vertical-align: middle;
  text-align: left;
  padding-left: 52px;
  font-size: 17px;
}
.btn.btn-login-cta .icon {
  fill: black;
  position: absolute;
  height: 32px;
  width: 32px;
  top: 50%;
  left: 10px;
  margin-top: -16px;
}
.btn.btn-login-cta .icon use > svg {
  fill: black;
}
.btn.btn-login-cta.facebook-cta .icon {
  fill: #3765a3;
}
.btn.btn-login-cta.facebook-cta .icon use > svg {
  fill: #3765a3;
}
.btn.btn-clear {
  background-color: none;
  border: none;
}
.btn.btn-search-header {
  background-color: initial;
  border: none;
  padding: 2px 12px 0px 12px;
}
.btn.btn-search-header span {
  color: white;
  font-weight: 800;
}
.btn.btn-white-hollow {
  border: 1px solid white;
  background-color: initial;
}
.svg-avatar-dims {
  width: 25px;
  height: 25px;
}
.svg-bin-dims {
  width: 24px;
  height: 24px;
}
.svg-brands-dims {
  width: 21px;
  height: 16px;
}
.svg-browse-arrow-dims {
  width: 2400px;
  height: 2400px;
}
.svg-carousel-left-dims {
  width: 3700px;
  height: 3700px;
}
.svg-carousel-right-dims {
  width: 3700px;
  height: 3700px;
}
.svg-carouselpager-active-dims {
  width: 1500px;
  height: 1500px;
}
.svg-carouselpager-inactive-dims {
  width: 1500px;
  height: 1500px;
}
.svg-close-dims {
  width: 20px;
  height: 20px;
}
.svg-close-naked-dims {
  width: 33px;
  height: 33px;
}
.svg-emailsignup-dims {
  width: 2500px;
  height: 2500px;
}
.svg-facebook-dims {
  width: 512px;
  height: 512px;
}
.svg-facebooklogo-dims {
  width: 2500px;
  height: 2500px;
}
.svg-features-dims {
  width: 2600px;
  height: 2600px;
}
.svg-floppy-dims {
  width: 22px;
  height: 22px;
}
.svg-follow-dims {
  width: 2500px;
  height: 2000px;
}
.svg-hamburger-dims {
  width: 39px;
  height: 32px;
}
.svg-key-dims {
  width: 23px;
  height: 23px;
}
.svg-login-dims {
  width: 2500px;
  height: 2500px;
}
.svg-logout-dims {
  width: 19px;
  height: 19px;
}
.svg-mail-dims {
  width: 512px;
  height: 512px;
}
.svg-save-dims {
  width: 1800px;
  height: 1800px;
}
.svg-search-dims {
  width: 2300px;
  height: 2400px;
}
.svg-settings-dims {
  width: 20px;
  height: 20px;
}
.svg-share-dims {
  width: 1800px;
  height: 2000px;
}
.svg-twitter-dims {
  width: 512px;
  height: 512px;
}
.svg-up-dims {
  width: 24px;
  height: 24px;
}
.svg-usenow-dims {
  width: 1600px;
  height: 1600px;
}
.list-filter-form {
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.list-filter-form .col-head .icon {
  right: 15px;
  left: initial;
}
.list-filter-form .col-head .icon.rotated {
  transform: rotate(180deg);
}
.list-filter-form form {
  padding: 20px;
  background-color: white;
}
.list-filter-form form .form-group:last-child {
  margin-bottom: 0px;
}
.list-filter-form form .search-input-group {
  border: 1px solid #e1e1e1;
}
.list-filter-form form .search-input-group .form-control {
  border: none;
}
.list-filter-form form .results-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  max-height: 300px;
  overflow: scroll;
}
.list-filter-form form .results-list.results-open {
  display: block;
}
.list-filter-form form .results-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: initial;
  padding: 0px 20px;
}
.list-filter-form form .results-list li:hover {
  background-color: #eee;
}
.list-filter-form form .results-list li a {
  padding: 5px 0px;
  color: black;
  display: block;
  font-weight: 400;
  font-size: 18px;
  height: 44px;
  line-height: 38px;
}
.list-filter-form form .results-list li:not(:last-child) a {
  border-bottom: 1px solid #ddd;
}
.list-filter-form.list-filter-form.scrolling {
  position: fixed;
  width: 262.5px;
  top: 50%;
}
.list-filter-form.list-filter-form.scrolling form {
  margin-bottom: 0;
}
input[type="text"],
input[type="password"] {
  height: 44px;
  line-height: 44px;
  padding: 0px 12px;
}
.form-group.no-margin {
  margin: 0px;
}
@media (max-width: 767px) {
  .form-wrapper {
    margin-bottom: 1em;
  }
}
.styled-select {
  overflow: hidden;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.styled-select select {
  background: transparent;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 14px;
  height: 44px;
  padding: 5px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 2;
  position: relative;
  transition: background 0.4s ease;
  color: #4e4d4d;
}
.styled-select select:hover {
  cursor: pointer;
  outline: 0;
}
.styled-select select:focus {
  cursor: pointer;
  outline: 0;
}
.styled-select select option {
  display: block;
  line-height: 1.5;
  font-size: 1em;
  border: none;
}
.styled-select.arrow-down {
  background: white no-repeat right center;
  height: 44px;
  width: 240px;
}
@media (max-width: 991px) {
  .styled-select.arrow-down {
    width: 210px;
  }
}
@media (max-width: 767px) {
  .styled-select.arrow-down {
    width: 100%;
  }
}
.styled-select.arrow-down select {
  border: 1px solid #fff;
  font-size: 16px;
  height: 44px;
  width: 100%;
  padding-left: 10px;
}
@media (max-width: 991px) {
  .styled-select.arrow-down select {
    width: 210px;
  }
}
@media (max-width: 767px) {
  .styled-select.arrow-down select {
    width: 100%;
  }
}
.styled-select.arrow-down svg.icon {
  position: absolute;
  right: 10px;
  top: 10px;
  fill: #cb5e27;
  z-index: 1;
  display: block;
  width: 22px;
  height: 22px;
}
.styled-select.arrow-down svg.icon use > svg {
  fill: #cb5e27;
}
body.home section.hero {
  height: 450px;
  background-color: #efefef;
  background-image: url('../images/hero_background_trimmed.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
body.home .home-page-title {
  font-size: 46px;
  margin-top: 0;
}
@media (max-width: 767px) {
  body.home .home-page-title {
    font-size: 22px;
    text-align: center;
  }
}
body.home main h1.main-title {
  text-align: center;
  margin-top: 0px;
  font-size: 54px;
}
body.home main h2.sub-title {
  font-size: 22.5px;
  text-align: center;
  margin-top: 31px;
}
body.home main p.description {
  text-align: center;
  font-family: 'vagroundedlight';
  font-weight: 200;
  font-size: 17.5px;
}
body.home main p.description a {
  color: #cb5e27;
}
body.home main section.main-content {
  padding-top: 45px;
}
body.brand .main-content {
  margin-top: 10px;
}
body.brand .brand-details .brand-logo {
  height: 251px;
  line-height: 251px;
  background-color: white;
  text-align: center;
  vertical-align: middle;
  width: 227px;
  margin-left: 20px;
  float: right;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  float: left;
  margin-left: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}
body.brand .brand-details .brand-logo img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 227px;
  max-height: 150px;
}
body.brand .brand-details .brand-logo .middle-helper-brand {
  display: inline-block;
  height: 190px;
  vertical-align: middle;
}
body.brand .brand-details .brand-logo .followers {
  line-height: normal;
  padding: 20px 0;
  border-top: 1px solid #e1e1e1;
  font-weight: 800;
  color: #333333;
  font-size: 18px;
}
body.brand .brand-details .brand-content {
  min-height: 251px;
  background-color: white;
  overflow: hidden;
  padding: 10px 0px;
  position: relative;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
}
body.brand .brand-details .brand-content h1 {
  margin: 0px;
  font-size: 38px;
}
body.brand .brand-details .brand-content h2 {
  font-size: 22px;
  margin: 12px 0px 13px 0px;
}
body.brand .brand-details .brand-content .sub-title {
  margin-top: 10px;
}
body.brand .brand-details .brand-content .description {
  font-size: 17px;
  line-height: 1.2;
}
body.brand .list-filter-container {
  float: right;
}
body.brand .followers-container {
  background-color: #fff;
  width: 100%;
  float: right;
  border-top: 1px solid #e1e1e1;
  position: absolute;
  bottom: 0;
}
body.brand .followers-container .followers {
  width: 100%;
  margin: 0;
  text-align: left;
  padding: 8px 20px;
}
body.brand .followers-container .followers p {
  margin: 0px;
  font-weight: 800;
  line-height: 1;
  color: #333333;
}
body.brand .followers-container .followers .line-1 {
  font-size: 16px;
  display: inline-block;
}
body.brand .followers-container .followers .line-2 {
  font-size: 18px;
  display: inline-block;
}
body.brand .followers-container .followers .line-3 {
  font-size: 16px;
  padding-top: 15px;
  display: inline-block;
}
body.brand .followers-container .followers .btn.btn-follow {
  border-radius: 5px;
  float: right;
  width: 224px;
}
body.brand .vouchr-list {
  overflow: hidden;
  padding-right: 2px;
}
body.brand .vouchr-list-no-directive {
  overflow: hidden;
  padding-right: 2px;
}
@media (max-width: 1199px) {
  body.brand .brand-details .brand-logo {
    height: 276px;
    line-height: 276px;
  }
  body.brand .brand-details .brand-logo .middle-helper-brand {
    height: 215px;
  }
  body.brand .brand-details .brand-content {
    min-height: 276px;
  }
  body.brand .followers-container .followers .btn.btn-follow {
    width: 119px;
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  body.brand .followers-container .followers .btn.btn-follow {
    width: 119px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  body.brand .brand-details .brand-logo {
    width: 100%;
    margin-bottom: 20px;
    height: 226px;
    line-height: 226px;
    margin-right: 0;
  }
  body.brand .brand-details .brand-logo .middle-helper-brand {
    height: 165px;
  }
  body.brand .brand-details .brand-content {
    min-height: 346px;
    float: left;
    margin-bottom: 20px;
  }
  body.brand .followers-container {
    width: 100%;
    margin: 0 auto;
    float: none;
  }
  body.brand .followers-container .followers .btn.btn-follow {
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
  }
  body.brand .list-filter-container {
    float: none;
  }
  body.brand .vouchr-list {
    width: 100%;
    margin: 0px auto;
    padding: 0;
  }
  body.brand .vouchr-list .vouchr-list-container {
    margin: 20px 0px;
    padding: 10px;
  }
  body.brand .vouchr-list .vouchr-list-container .vouchr-container {
    margin: 0 auto;
    padding: 0;
    border-radius: 5px;
  }
  body.brand .vouchr-list .vouchr-list-container .vouchr-container .btn-view-vouchr {
    right: 0px;
    width: 222px;
    bottom: 0;
  }
  body.brand .vouchr-list-no-directive {
    width: 100%;
    margin: 0px auto;
    padding: 0;
  }
  body.brand .vouchr-list-no-directive .vouchr-list-container {
    margin: 20px 0px;
    padding: 10px;
  }
  body.brand .vouchr-list-no-directive .vouchr-list-container .vouchr-container {
    margin: 0 auto;
    padding: 0;
    border-radius: 5px;
  }
  body.brand .vouchr-list-no-directive .vouchr-list-container .vouchr-container .btn-view-vouchr {
    right: 0px;
    width: 222px;
    bottom: 0;
  }
  body.brand .list-filter-form .category-select-box,
  body.brand .list-filter-form .location-select-box {
    width: 100%;
  }
}
body.brand-list .brand-list-header:last-child {
  margin: 40px auto;
}
body.brand-list .brand-list-header .brand-list-content {
  min-height: 177px;
  background-color: white;
  overflow: hidden;
  padding: 15px 20px;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
}
body.brand-list .brand-list-header .brand-list-content h1 {
  margin: 0px;
  font-size: 38px;
}
body.brand-list .brand-list-header .brand-list-content h2 {
  font-size: 22px;
  margin: 12px 0px 13px 0px;
}
body.brand-list .brand-list-header .brand-list-content .brand-list-alphabet {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #dfdfdf;
}
body.brand-list .brand-list-header .brand-list-content .brand-list-alphabet .brand-list-alphabet__letter {
  padding: 0 10px;
  display: inline-block;
}
body.brand-list .brand-list-header .brand-list-content .brand-list-alphabet .brand-list-alphabet__letter a {
  text-decoration: none;
  color: #747474;
}
body.brand-list .brand-list-header .brand-list-content.brand-list-margin {
  margin-top: 40px;
}
body.brand-list .brand-list-header .brand-list-content.brand-list-margin:first-child {
  margin-top: 0px;
}
body.brand-list .brand-list-header .brand-list-content.brand-list-margin .brand-list-link {
  padding: 5px 0;
}
body.brand-list .brand-list-header .brand-list-content.brand-list-margin .brand-list-link a {
  text-decoration: none;
  color: #313131;
}
body.vouchr .vouchr-content-container {
  background-color: white;
  padding: 50px 25px;
  margin-bottom: 40px;
  min-height: 325px;
  position: relative;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
}
body.vouchr .vouchr-content-container .coupon {
  margin: 0px;
}
body.vouchr .vouchr-content-container .vouchr-content {
  overflow: hidden;
  padding-left: 50px;
}
body.vouchr .vouchr-content-container .vouchr-content h1 {
  margin-top: 0px;
  font-size: 35px;
}
body.vouchr .vouchr-content-container .vouchr-content h2 {
  font-size: 22px;
  margin-top: 10px;
}
body.vouchr .vouchr-content-container .cta-container {
  list-style-type: none;
  position: absolute;
  right: 20px;
  bottom: 50px;
}
body.vouchr .vouchr-content-container .cta-container li {
  float: left;
}
body.vouchr .vouchr-content-container .cta-container li:not(:first-child) {
  margin-left: 5px;
}
body.vouchr .vouchr-content-container .cta-container li:not(:last-child) {
  margin-right: 5px;
}
body.vouchr .brand-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0;
  float: left;
  height: 251px;
}
body.vouchr .brand-container .middle-helper-brand {
  display: inline-block;
  height: 190px;
  vertical-align: middle;
}
body.vouchr .brand-description-container {
  width: 600px;
  margin-left: 20px;
  float: right;
  min-height: 251px;
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
}
body.vouchr .brand-description-container h1 {
  margin: 0px;
  font-size: 38px;
}
body.vouchr .brand-description-container h2 {
  font-size: 22px;
  margin: 12px 0px 13px 0px;
}
body.vouchr .brand-description-container .sub-title {
  margin-top: 10px;
  min-height: 52px;
}
body.vouchr .brand-description-container .description {
  font-size: 17px;
  line-height: 1.2;
  margin: 0 0 8px;
  min-height: 59px;
}
body.vouchr .followers-container {
  clear: both;
  background-color: white;
  border-top: 1px solid #e1e1e1;
  width: 227px;
  margin-left: 20px;
  float: right;
  margin: 0;
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
body.vouchr .followers-container.expired-vouchr-followers {
  border-top: 0;
}
body.vouchr .followers-container.expired-vouchr-followers .followers {
  padding: 0;
}
body.vouchr .followers-container.expired-vouchr-followers .followers .btn.btn-follow {
  border-radius: 5px;
  float: right;
  width: 100%;
}
body.vouchr .followers-container .followers {
  width: 100%;
  margin: 0;
  text-align: left;
  padding: 8px 20px;
}
body.vouchr .followers-container .followers p {
  margin: 0px;
  font-weight: 800;
  line-height: 1;
  color: #333333;
  font-size: 18px;
}
body.vouchr .followers-container .followers .line-1 {
  font-size: 16px;
  display: inline-block;
}
body.vouchr .followers-container .followers .line-2 {
  font-size: 18px;
  display: inline-block;
}
body.vouchr .followers-container .followers .line-3 {
  font-size: 16px;
  padding-top: 15px;
  display: inline-block;
}
body.vouchr .followers-container .followers .btn.btn-follow {
  border-radius: 5px;
  float: right;
  width: 224px;
}
body.vouchr .vouchr-list {
  overflow: hidden;
}
@media (max-width: 1199px) {
  body.vouchr .vouchr-content-container .vouchr-content h4 {
    margin-top: -5px;
    font-size: 16px;
  }
  body.vouchr .brand-description-container {
    width: 450px;
  }
  body.vouchr .followers-container .followers .btn.btn-follow {
    width: 119px;
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  body.vouchr .brand-description-container {
    width: 473px;
  }
  body.vouchr .followers-container .followers .btn.btn-follow {
    width: 119px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  body.vouchr .vouchr-content-container .coupon {
    float: none !important;
    display: block;
    margin: 0 auto;
  }
  body.vouchr .vouchr-content-container .vouchr-content {
    display: block;
    text-align: center;
    margin: 10px 0;
    padding: 0;
  }
  body.vouchr .vouchr-content-container .vouchr-content h4 {
    margin-top: 10px;
    font-size: 18px;
  }
  body.vouchr .vouchr-content-container .cta-container {
    position: initial;
    margin-top: 10px;
  }
  body.vouchr .vouchr-content-container .cta-container:before,
  body.vouchr .vouchr-content-container .cta-container:after {
    content: " ";
    display: table;
  }
  body.vouchr .vouchr-content-container .cta-container:after {
    clear: both;
  }
  body.vouchr .vouchr-content-container .cta-container li {
    width: 100%;
    margin-bottom: 10px;
  }
  body.vouchr .vouchr-content-container .cta-container li:not(:first-child) {
    margin-left: 0px;
  }
  body.vouchr .vouchr-content-container .cta-container li:not(:last-child) {
    margin-right: 0px;
  }
  body.vouchr .vouchr-content-container .cta-container li a,
  body.vouchr .vouchr-content-container .cta-container li button {
    width: 100%;
  }
  body.vouchr .brand-container .middle-helper-brand {
    height: 165px;
  }
  body.vouchr .vouchr-list-container {
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
  body.vouchr .vouchr-list-container .brand-followers-container {
    margin: 20px auto;
    border-radius: 5px;
  }
  body.vouchr .vouchr-list-container .brand-container {
    width: 100%;
    height: 212px;
    margin: 0;
    float: none;
  }
  body.vouchr .vouchr-list-container .brand-description-container {
    width: 100%;
    margin: 10px 0 0 0;
    height: initial;
    background-color: white;
  }
  body.vouchr .vouchr-list-container .followers-container {
    width: 100%;
  }
  body.vouchr .vouchr-list-container .followers-container .followers {
    border: 0;
    border-left: 0px solid #e1e1e1;
    margin: 0;
    width: 100%;
    text-align: left;
    font-weight: 800;
    color: #919191;
  }
  body.vouchr .vouchr-list-container .followers-container .followers .line-3 {
    padding-top: 10px;
  }
  body.vouchr .vouchr-list-container .followers-container .btn.btn-follow {
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
  }
  body.vouchr .vouchr-list-container .vouchr-list .vouchr-list-container {
    background-color: #e1e1e1;
    padding: 10px;
    margin: 20px 20px;
  }
  body.vouchr .vouchr-list-container .vouchr-list-no-directive .vouchr-list-container {
    background-color: #e1e1e1;
    padding: 10px;
    margin: 20px 20px;
  }
  body.vouchr .list-filter-form .category-select-box,
  body.vouchr .list-filter-form .location-select-box {
    width: 100%;
  }
}
body.vouchr-list-page .vouchr-list-header .vouchr-list-content {
  min-height: 177px;
  background-color: white;
  overflow: hidden;
  padding: 15px 20px;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
}
body.vouchr-list-page .vouchr-list-header .vouchr-list-content h1 {
  margin: 0px;
  font-size: 38px;
}
body.vouchr-list-page .vouchr-list-header .vouchr-list-content h2 {
  font-size: 22px;
  margin: 12px 0px 13px 0px;
}
body.vouchr-list-page .vouchr-list.vouchr-listing {
  background: #fff;
}
body.vouchr-list-page .vouchr-list.vouchr-listing .vouchr-list-container {
  background-color: #fff;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
body.vouchr-list-page .vouchr-list.vouchr-listing .vouchr-list-container:last-child .vouchr-divider {
  border-top: 0px solid #dfdfdf;
}
body.vouchr-list-page .vouchr-list.vouchr-listing .vouchr-list-container .vouchr-title {
  color: #313131;
  font-size: 17px;
  line-height: 20px;
}
body.vouchr-list-page .vouchr-list.vouchr-listing .vouchr-list-container .vouchr-description {
  color: #313131;
  font-size: 15px;
  line-height: 19px;
}
body.vouchr-list-page .vouchr-list.vouchr-listing .vouchr-list-container .vouchr-list-link {
  display: block;
  margin-top: 20px;
  color: #cb5e27;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}
body.vouchr-list-page .vouchr-list.vouchr-listing .vouchr-list-container .vouchr-list-view-link {
  display: block;
  margin-top: 20px;
  color: #d19b02;
  font-size: 17px;
  line-height: 18px;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  font-weight: 700;
}
body.vouchr-list-page .vouchr-list.vouchr-listing .vouchr-list-container .vouchr-divider {
  margin-top: 10px;
  border-top: 1px solid #dfdfdf;
}
body.saved-vouchr main h1.main-title {
  text-align: center;
  margin-top: 0px;
  font-size: 54px;
}
body.saved-vouchr main section.main-content {
  padding-top: 45px;
}
body.saved-vouchr main section.main-content .view-all {
  padding: 0 10px;
  border-left: 1px solid #4e4d4d;
  cursor: pointer;
}
body.settings main h1.main-title {
  text-align: center;
  margin-top: 0px;
  font-size: 54px;
}
body.settings main section.main-content {
  padding-top: 45px;
}
body.settings main section.main-content p.description {
  text-align: left;
  font-family: vagroundedlight;
  font-weight: 200;
  font-size: 14.5px;
}
body.settings main section.main-content .btn-save-changes {
  background-color: #d7a602;
  color: white;
  padding: 8px 29px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  margin-left: 15px;
}
body.settings main section.main-content .btn-save-changes:hover {
  color: #cb5e27;
}
body.settings main section.main-content .form-inline .form-group {
  margin: 10px 0;
}
body.settings main section.main-content .form-inline .form-group label {
  width: 150px;
  color: #4e4d4d;
}
body.settings main section.main-content .form-inline .checkbox {
  margin: 35px 0 50px 15px;
  display: block;
  color: #4e4d4d;
}
body.settings main section.main-content .form-inline .checkbox label {
  width: 100%;
}
body.settings main section.main-content .form-inline .checkbox label .label-text {
  display: inline-block;
  width: 90%;
  padding-left: 20px;
}
body.settings main section.main-content .form-inline input[type="text"],
body.settings main section.main-content .form-inline input[type="password"],
body.settings main section.main-content .form-inline input[type="email"] {
  height: 40px;
  padding: 3px 6px;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  width: 240px !important;
  color: #4e4d4d;
}
@media (max-width: 991px) {
  body.settings main section.main-content .form-inline input[type="text"],
  body.settings main section.main-content .form-inline input[type="password"],
  body.settings main section.main-content .form-inline input[type="email"] {
    width: 210px !important;
  }
}
@media (max-width: 767px) {
  body.settings main section.main-content .form-inline input[type="text"],
  body.settings main section.main-content .form-inline input[type="password"],
  body.settings main section.main-content .form-inline input[type="email"] {
    width: 100% !important;
  }
}
body.settings main section.main-content .form-inline input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 5px;
  outline: 0;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  vertical-align: text-bottom;
}
@media (max-width: 991px) {
  body.settings main section.main-content .form-inline input[type='checkbox'] {
    vertical-align: top;
  }
}
body.settings main section.main-content .form-inline input[type='checkbox']:focus {
  outline: 0;
}
body.settings main section.main-content .form-inline input[type='checkbox']:checked {
  background: #cb5e27;
  outline: 0;
}
body.settings main .col-head {
  margin-bottom: 10px;
}
body.settings main .divider {
  width: 100%;
  border-color: #e1e1e1;
  margin: 35px 0px;
}
body.settings main .terms-links {
  margin-left: 0px;
}
body.settings main .terms-links a {
  color: #cb5e27;
  text-decoration: underline;
  font-size: 14px;
}
body.settings main .terms-links .separator {
  margin: 0px 20px -3px 5px;
  display: inline-block;
  border-right: 1px solid black;
  width: 20px;
  height: 15px;
}
body.password main h1.main-title {
  text-align: center;
  margin-top: 0px;
  font-size: 54px;
}
body.password main section.main-content {
  padding-top: 45px;
}
body.password main section.main-content .btn-save-changes {
  background-color: #d7a602;
  color: white;
  padding: 8px 29px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  margin-left: 15px;
}
body.password main section.main-content .btn-save-changes:hover {
  color: #cb5e27;
}
body.password main section.main-content .form-inline .form-group {
  margin: 10px 0;
}
body.password main section.main-content .form-inline .form-group label {
  width: 100px;
  color: #4e4d4d;
}
body.password main section.main-content .form-inline .checkbox {
  margin: 35px 0 50px 15px;
  display: block;
  color: #4e4d4d;
}
body.password main section.main-content .form-inline .checkbox label {
  width: 100%;
}
body.password main section.main-content .form-inline .checkbox label .label-text {
  display: inline-block;
  width: 90%;
  padding-left: 20px;
}
body.password main section.main-content .form-inline input[type="text"],
body.password main section.main-content .form-inline input[type="password"],
body.password main section.main-content .form-inline input[type="email"] {
  height: 40px;
  padding: 3px 6px;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  width: 240px !important;
  color: #4e4d4d;
}
@media (max-width: 991px) {
  body.password main section.main-content .form-inline input[type="text"],
  body.password main section.main-content .form-inline input[type="password"],
  body.password main section.main-content .form-inline input[type="email"] {
    width: 210px !important;
  }
}
@media (max-width: 767px) {
  body.password main section.main-content .form-inline input[type="text"],
  body.password main section.main-content .form-inline input[type="password"],
  body.password main section.main-content .form-inline input[type="email"] {
    width: 100% !important;
  }
}
body.password main section.main-content .form-inline input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 5px;
  outline: 0;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  vertical-align: text-bottom;
}
@media (max-width: 991px) {
  body.password main section.main-content .form-inline input[type='checkbox'] {
    vertical-align: top;
  }
}
body.password main section.main-content .form-inline input[type='checkbox']:focus {
  outline: 0;
}
body.password main section.main-content .form-inline input[type='checkbox']:checked {
  background: #cb5e27;
  outline: 0;
}
body.password main .col-head {
  margin-bottom: 10px;
}
body.password main .divider {
  width: 100%;
  border-color: #e1e1e1;
  margin: 35px 15px;
}
body.password main .terms-links {
  margin-left: 0px;
}
body.password main .terms-links a {
  color: #cb5e27;
  text-decoration: underline;
  font-size: 14px;
}
body.password main .terms-links .separator {
  margin: 0px 20px -3px 5px;
  display: inline-block;
  border-right: 1px solid black;
  width: 20px;
  height: 15px;
}
body.category .main-content {
  margin-top: 10px;
}
body.category .category-details .category-logo {
  height: 226px;
  line-height: 226px;
  background-color: white;
  text-align: center;
  vertical-align: middle;
  width: 227px;
  margin-left: 20px;
  float: right;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  float: left;
  margin-left: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}
body.category .category-details .category-logo img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 227px;
}
body.category .category-details .category-logo .followers {
  line-height: normal;
  padding: 20px 0;
  border-top: 1px solid #e1e1e1;
  font-weight: 800;
  color: #333333;
  font-size: 18px;
}
body.category .category-details .category-content {
  min-height: 226px;
  background-color: white;
  overflow: hidden;
  padding: 10px 0px;
  position: relative;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
}
body.category .category-details .category-content h1 {
  margin: 0px;
  font-size: 38px;
}
body.category .category-details .category-content h2 {
  font-size: 22px;
  margin: 12px 0px 13px 0px;
}
body.category .category-details .category-content .description {
  font-size: 17px;
  line-height: 1.1;
}
body.category .list-filter-container {
  float: right;
}
body.category .followers-container {
  background-color: #fff;
  width: 100%;
  float: right;
  border-top: 1px solid #e1e1e1;
  position: absolute;
  bottom: 0;
}
body.category .followers-container .followers {
  width: 100%;
  margin: 0;
  text-align: left;
  padding: 8px 20px;
}
body.category .followers-container .followers p {
  margin: 0px;
  font-weight: 800;
  line-height: 1;
  color: #333333;
}
body.category .followers-container .followers .line-1 {
  font-size: 16px;
  display: inline-block;
}
body.category .followers-container .followers .line-2 {
  font-size: 18px;
  display: inline-block;
}
body.category .followers-container .followers .line-3 {
  font-size: 16px;
  padding-top: 15px;
  display: inline-block;
}
body.category .followers-container .followers .btn.btn-follow {
  border-radius: 5px;
  float: right;
  width: 224px;
}
body.category .vouchr-list {
  overflow: hidden;
  padding-right: 2px;
}
body.category .vouchr-list-no-directive {
  overflow: hidden;
  padding-right: 2px;
}
@media (max-width: 1199px) {
  body.category .followers-container .followers .btn.btn-follow {
    width: 119px;
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  body.category .followers-container .followers .btn.btn-follow {
    width: 119px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  body.category .category-details .category-logo {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
  body.category .category-details .category-content {
    min-height: 320px;
    float: left;
    margin-bottom: 20px;
  }
  body.category .followers-container {
    width: 100%;
    margin: 0 auto;
    float: none;
  }
  body.category .followers-container .followers .btn.btn-follow {
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
  }
  body.category .list-filter-container {
    float: none;
  }
  body.category .vouchr-list {
    width: 100%;
    margin: 0px auto;
    padding: 0;
  }
  body.category .vouchr-list .vouchr-list-container {
    margin: 20px 0px;
    padding: 10px;
  }
  body.category .vouchr-list .vouchr-list-container .vouchr-container {
    margin: 0 auto;
    padding: 0;
    border-radius: 5px;
  }
  body.category .vouchr-list .vouchr-list-container .vouchr-container .btn-view-vouchr {
    right: 0px;
    width: 222px;
    bottom: 0;
  }
  body.category .vouchr-list-no-directive {
    width: 100%;
    margin: 0px auto;
    padding: 0;
  }
  body.category .vouchr-list-no-directive .vouchr-list-container {
    margin: 20px 0px;
    padding: 10px;
  }
  body.category .vouchr-list-no-directive .vouchr-list-container .vouchr-container {
    margin: 0 auto;
    padding: 0;
    border-radius: 5px;
  }
  body.category .vouchr-list-no-directive .vouchr-list-container .vouchr-container .btn-view-vouchr {
    right: 0px;
    width: 222px;
    bottom: 0;
  }
}
body.category-list .category-list-header:last-child {
  margin: 40px auto;
}
body.category-list .category-list-header .category-list-content {
  min-height: 177px;
  background-color: white;
  overflow: hidden;
  padding: 15px 20px;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
}
body.category-list .category-list-header .category-list-content h1 {
  margin: 0px;
  font-size: 38px;
}
body.category-list .category-list-header .category-list-content h2 {
  font-size: 22px;
  margin: 12px 0px 13px 0px;
}
body.category-list .category-list-header .category-list-content .category-list-alphabet {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #dfdfdf;
}
body.category-list .category-list-header .category-list-content .category-list-alphabet .category-list-alphabet__letter {
  padding: 0 10px;
  display: inline-block;
}
body.category-list .category-list-header .category-list-content .category-list-alphabet .category-list-alphabet__letter a {
  text-decoration: none;
  color: #747474;
}
body.category-list .category-list-header .category-list-content.category-list-margin {
  margin-top: 40px;
}
body.category-list .category-list-header .category-list-content.category-list-margin:first-child {
  margin-top: 0px;
}
body.category-list .category-list-header .category-list-content.category-list-margin .category-list-link {
  padding: 5px 0;
}
body.category-list .category-list-header .category-list-content.category-list-margin .category-list-link a {
  text-decoration: none;
  color: #313131;
}
body.thank-you main h1.main-title {
  text-align: center;
  margin-top: 0px;
  font-size: 54px;
}
body.thank-you main h2.sub-title {
  font-size: 22.5px;
  text-align: center;
  margin-top: 31px;
}
body.thank-you main p.description {
  text-align: center;
  font-family: 'vagroundedlight';
  font-weight: 200;
  font-size: 17.5px;
}
body.thank-you main p.description a {
  color: #cb5e27;
}
body.thank-you main section.main-content {
  padding-top: 45px;
}
body.thank-you .vouchr-list {
  margin-top: 0;
}
body.thank-you .vouchr-list .vouchr-list-container {
  margin: 0;
}
body.thank-you .vouchr-list .vouchr-list-container .brand-container {
  height: 270px;
  background-color: white;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  margin-top: 20px;
  float: left;
}
@media (max-width: 767px) {
  body.thank-you .vouchr-list .vouchr-list-container {
    background-color: #e1e1e1;
    padding: 10px;
    margin: 20px 0;
    border-radius: 0px;
  }
  body.thank-you .vouchr-list .vouchr-list-container:before,
  body.thank-you .vouchr-list .vouchr-list-container:after {
    content: " ";
    display: table;
  }
  body.thank-you .vouchr-list .vouchr-list-container:after {
    clear: both;
  }
  body.thank-you .vouchr-list .vouchr-list-container .brand-container {
    height: 182px;
    margin-bottom: 10px;
  }
}
body.subscribed-brand main h1.main-title {
  text-align: center;
  margin-top: 0px;
  font-size: 54px;
}
body.subscribed-brand main .main-content {
  padding-top: 45px;
}
body.subscribed-brand .vouchr-list {
  margin-top: 0;
}
body.subscribed-brand .vouchr-list .vouchr-list-container {
  margin: 0;
}
body.subscribed-brand .vouchr-list .vouchr-list-container .brand-container {
  height: 270px;
  background-color: white;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  margin-top: 20px;
  float: left;
}
@media (max-width: 767px) {
  body.subscribed-brand .vouchr-list .vouchr-list-container {
    background-color: #e1e1e1;
    padding: 10px;
    margin: 20px 0;
    border-radius: 0px;
  }
  body.subscribed-brand .vouchr-list .vouchr-list-container:before,
  body.subscribed-brand .vouchr-list .vouchr-list-container:after {
    content: " ";
    display: table;
  }
  body.subscribed-brand .vouchr-list .vouchr-list-container:after {
    clear: both;
  }
  body.subscribed-brand .vouchr-list .vouchr-list-container .brand-container {
    height: 182px;
    margin-bottom: 10px;
  }
}
body.contactus main h1.main-title {
  text-align: center;
  margin-top: 0px;
  font-size: 54px;
}
body.contactus main section.main-content {
  padding-top: 45px;
}
body.contactus main section.main-content .btn-save-changes {
  background-color: #d7a602;
  color: white;
  padding: 8px 29px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  margin-left: 15px;
}
body.contactus main section.main-content .btn-save-changes:hover {
  color: #cb5e27;
}
body.contactus main section.main-content .form-inline .form-group {
  margin: 10px 0;
}
body.contactus main section.main-content .form-inline .form-group label {
  width: 100px;
  color: #4e4d4d;
}
body.contactus main section.main-content .form-inline .checkbox {
  margin: 35px 0 50px 15px;
  display: block;
  color: #4e4d4d;
}
body.contactus main section.main-content .form-inline .checkbox label {
  width: 100%;
}
body.contactus main section.main-content .form-inline .checkbox label .label-text {
  display: inline-block;
  width: 90%;
  padding-left: 20px;
}
body.contactus main section.main-content .form-inline input[type="text"],
body.contactus main section.main-content .form-inline input[type="password"],
body.contactus main section.main-content .form-inline input[type="email"] {
  height: 40px;
  padding: 3px 6px;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  width: 440px !important;
  color: #4e4d4d;
}
@media (max-width: 767px) {
  body.contactus main section.main-content .form-inline input[type="text"],
  body.contactus main section.main-content .form-inline input[type="password"],
  body.contactus main section.main-content .form-inline input[type="email"] {
    width: 100% !important;
  }
}
body.contactus main section.main-content .form-inline textarea {
  width: 440px !important;
  height: 150px !important;
}
@media (max-width: 767px) {
  body.contactus main section.main-content .form-inline textarea {
    width: 100% !important;
  }
}
body.contactus main section.main-content .form-inline .styled-select.arrow-down {
  width: 440px !important;
}
@media (max-width: 767px) {
  body.contactus main section.main-content .form-inline .styled-select.arrow-down {
    width: 100% !important;
  }
}
body.contactus main section.main-content .form-inline input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 5px;
  outline: 0;
  box-shadow: 1px 1px 2px #e1e1e1;
  -moz-box-shadow: 1px 1px 2px #e1e1e1;
  -webkit-box-shadow: 1px 1px 2px #e1e1e1;
  vertical-align: text-bottom;
}
@media (max-width: 991px) {
  body.contactus main section.main-content .form-inline input[type='checkbox'] {
    vertical-align: top;
  }
}
body.contactus main section.main-content .form-inline input[type='checkbox']:focus {
  outline: 0;
}
body.contactus main section.main-content .form-inline input[type='checkbox']:checked {
  background: #cb5e27;
  outline: 0;
}
body.contactus main .col-head {
  margin-bottom: 10px;
}
body.contactus main .divider {
  width: 100%;
  border-color: #e1e1e1;
  margin: 35px 15px;
}
body.contactus main .terms-links {
  margin-left: 0px;
}
body.contactus main .terms-links a {
  color: #cb5e27;
  text-decoration: underline;
  font-size: 14px;
}
body.contactus main .terms-links .separator {
  margin: 0px 20px -3px 5px;
  display: inline-block;
  border-right: 1px solid black;
  width: 20px;
  height: 15px;
}
.modal-backdrop {
  opacity: 0.5;
}
.modal .modal-header {
  padding: 0px 0px 0px 30px;
  background-color: #cb5e27;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  color: white;
  height: 45px;
}
.modal .modal-header h1,
.modal .modal-header h2,
.modal .modal-header h3,
.modal .modal-header h4 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 45px;
  font-size: 18px;
}
.modal .modal-header .close {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 11px;
  padding-bottom: 13px;
}
.modal.alert p,
.modal.confirm p {
  font-size: 16px;
}
.modal a {
  color: #cb5e27;
}
.modal.login .modal-dialog {
  width: 708px;
}
.modal.login .modal-body {
  padding: 20px;
}
.modal.login .login-content {
  margin-top: 20px;
}
.modal.login .signup-content {
  padding-left: 49px;
  float: left;
  display: inline-block;
}
.modal.login .signup-content ul {
  list-style-type: none;
}
.modal.login .signup-content ul li:not(:last-child) {
  margin-bottom: 12px;
}
.modal.login .login-form {
  display: block;
  float: left;
  width: 375px;
  margin-bottom: 0px;
}
.modal.login .login-form .input-fields {
  padding-right: 47px;
  border-right: 1px solid black;
}
.modal.login .login-form .login-btn {
  text-align: left;
  width: 266px;
  margin-top: 10px;
}
.modal.login .login-form .notify {
  padding-top: 20px;
}
@media (max-width: 767px) {
  .modal.login .modal-dialog {
    width: inherit;
  }
  .modal.login .signup-content {
    padding-left: 0px;
    width: 100%;
  }
  .modal.login .login-form {
    width: 100%;
    border-bottom: 1px solid black;
    margin-bottom: 20px;
  }
  .modal.login .login-form .input-fields {
    padding-right: 0;
    border-right: 0;
  }
  .modal.login .login-form .login-btn {
    width: 100%;
  }
  .modal.login .btn.btn-login-cta {
    width: 100%;
  }
}
.modal.login h1 {
  margin-top: 0px;
}
.modal.login p {
  margin-bottom: 0px;
}
.modal.register h1 {
  margin-top: 0px;
}
.modal.register form {
  margin-bottom: 0px;
}
.modal.register .alert {
  padding: 5px 15px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.modal.register .input-group-btn button.btn.btn-default {
  padding: 14px 12px;
}
.modal.privacy .privacy-content {
  max-height: 678px;
  overflow: scroll;
}
.modal.privacy .privacy-content li {
  margin-left: 20px;
}
.modal.terms .terms-content {
  max-height: 678px;
  overflow: scroll;
}
.modal.terms .terms-content p span {
  padding-left: 20px;
  display: inline-block;
  padding-top: 5px;
}
.modal.terms .terms-content li {
  margin-left: 20px;
}
.modal.share .modal-dialog {
  width: 708px;
}
.modal.share .modal-body {
  padding: 20px;
}
.modal.share .share-content .share-content-icons {
  padding: 15px 0;
}
.modal.share .share-content .btn-share.btn-share-facebook svg.icon {
  fill: #3765a3;
  width: 50px;
  height: 50px;
}
.modal.share .share-content .btn-share.btn-share-facebook svg.icon use > svg {
  fill: #3765a3;
}
.modal.share .share-content .btn-share.btn-share-twitter svg.icon {
  fill: #53A7DB;
  width: 50px;
  height: 50px;
}
.modal.share .share-content .btn-share.btn-share-twitter svg.icon use > svg {
  fill: #53A7DB;
}
.modal.share .share-content .btn-share.btn-share-mail {
  background-color: #F6EB5C;
  padding: 4px 6px;
  margin-top: 8px;
  margin-left: 6px;
}
.modal.share .share-content .btn-share.btn-share-mail svg.icon {
  fill: white;
  width: 40px;
  height: 40px;
}
.modal.share .share-content .btn-share.btn-share-mail svg.icon use > svg {
  fill: white;
}
@media (max-width: 767px) {
  .modal.share .modal-dialog {
    width: inherit;
  }
}
.modal.share h1 {
  margin-top: 0px;
}
.modal.share p {
  margin-bottom: 0px;
}
