.modal.login {
    .modal-dialog {
        width: 708px;
    }

    .modal-body {
        padding: 20px;
    }

    .login-content {
        margin-top: 20px;
    }

    .signup-content {
        padding-left: 49px;
        float: left;
        display: inline-block;

        ul {
            list-style-type: none;

            li {
                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }
        }
    }

    .login-form {
        display: block;
        float: left;
        width: 375px;
        margin-bottom: 0px;

        .input-fields {
            padding-right: 47px;
            border-right: 1px solid black;
        }

        .login-btn {
            text-align: left;
            width: 266px;
            margin-top: 10px;
        }

        .notify {
            padding-top: 20px;
        }
    }

    @media (max-width: 767px) {
        .modal-dialog {
            width: inherit;
        }

        .signup-content {
            padding-left: 0px;
            width: 100%;
        }

        .login-form {
            width: 100%;
            border-bottom: 1px solid black;
            margin-bottom: 20px;

            .input-fields {
                padding-right: 0;
                border-right: 0;
            }

            .login-btn {
                width: 100%;
            }
        }

        .btn.btn-login-cta {
            width: 100%;
        }
    }

    h1 {
        margin-top: 0px;
    }

    p {
        margin-bottom: 0px;
    }
}