body.category {
    .main-content {
        margin-top: 10px;
    }

    .category-details {
        .category-logo {
            height: 226px;
            line-height: 226px;
            background-color: white;
            text-align: center;
            vertical-align: middle;

            .category-column();
            .shadow();

            float: left;
            margin-left: 0;
            margin-right: 20px;
            margin-bottom: 20px;

            img {
                display: inline-block;
                width: auto;
                height: auto;
                max-width: 227px;
                // max-height: 150px;
                // margin-top: 60px;
            }

            .followers {
                line-height: normal;
                padding: 20px 0;
                border-top: 1px solid @vouchr-color-grey-border;
                font-weight: 800;
                color: @vouchr-color-followers;
                font-size: 18px;
            }
        }

        .category-content {
            min-height: 226px;
            background-color: white;
            overflow: hidden;
            padding: 10px 0px;
            position: relative;

            .shadow();

            h1 {
                margin: 0px;
                font-size: 38px;
            }

            h2 {
                font-size: 22px;
                margin: 12px 0px 13px 0px;
            }

            .description {
                font-size: 17px;
                line-height: 1.1;
            }
        }
    }

    .list-filter-container {
        float: right;
    }

    .list-filter-form {
        .brand-select-box {
            // display: none;
        }
    }

    .followers-container {
        background-color: #fff;
        width: 100%;
        float: right;
        border-top: 1px solid @vouchr-color-grey-border;
        position: absolute;
        bottom: 0;

        .followers {
            width: 100%;
            margin: 0;
            text-align: left;
            padding: 8px 20px;

            p {
                margin: 0px;
                font-weight: 800;
                line-height: 1;
                color: @vouchr-color-followers;
            }

            .line-1 {
                font-size: 16px;
                display: inline-block;
            }

            .line-2 {
                font-size: 18px;
                display: inline-block;
            }

            .line-3 {
                font-size: 16px;
                padding-top: 15px;
                display: inline-block;
            }

            .btn {
                &.btn-follow {
                    border-radius: 5px;
                    float: right;
                    width: 224px;
                }
            }
        }
    }

    .vouchr-list {
        overflow: hidden;
        padding-right: 2px; // allows box-shadow on vouchr listings to be visible on right side of container
    }

    .vouchr-list-no-directive {
        overflow: hidden;
        padding-right: 2px; // allows box-shadow on vouchr listings to be visible on right side of container
    }
}

@media(max-width: @screen-md-max) {
    body.category {
        .followers-container {
            .followers {
                .btn {
                    &.btn-follow {
                        width: 119px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media(max-width: @screen-sm-max) {
    body.category {
        .followers-container {
            .followers {
                .btn {
                    &.btn-follow {
                        width: 119px;
                        font-size: 18px;
                    }
                }
            }
        }

        .list-filter-form {
            .category-select-box,
            .location-select-box {
                // width: 50%;
            }
        }
    }
}

@media(max-width: @screen-xs-max) {
    body.category {

        .category-details {

            .category-logo {
                width: 100%;
                margin-bottom: 20px;
                // height: 166px;
                // line-height: 166px;
                margin-right: 0;
            }

            .category-content {
                min-height: 320px;
                float: left;
                margin-bottom: 20px;
            }
        }

        .followers-container {
            width: 100%;
            margin: 0 auto;
            float: none;

            .followers {

                .btn {
                    &.btn-follow {
                        width: 100%;
                        border-radius: 5px;
                        margin-top: 20px;
                    }
                }
            }
        }

        .list-filter-container {
            float: none;
        }

        .vouchr-list {
            width: 100%;
            margin: 0px auto;
            padding: 0;

            .vouchr-list-container {
                margin: 20px 0px;
                padding: 10px;

                .vouchr-container {
                    margin: 0 auto;
                    padding: 0;
                    .round-box();

                    .coupon {
                        // height: 270px;
                    }

                    .btn-view-vouchr {
                        right: 0px;
                        width: 222px;
                        bottom: 0;

                        .view-offer-vouchr-text {

                        }
                    }
                }
            }
        }

        .vouchr-list-no-directive {
            width: 100%;
            margin: 0px auto;
            padding: 0;

            .vouchr-list-container {
                margin: 20px 0px;
                padding: 10px;

                .vouchr-container {
                    margin: 0 auto;
                    padding: 0;
                    .round-box();

                    .coupon {
                        // height: 270px;
                    }

                    .btn-view-vouchr {
                        right: 0px;
                        width: 222px;
                        bottom: 0;

                        .view-offer-vouchr-text {

                        }
                    }
                }
            }
        }

        .list-filter-form {
            .category-select-box,
            .location-select-box {
                // width: 100%;
            }
        }
    }
}