.btn {
    &.light {
        background-color: @vouchr-color-babypoo;
        color: white;
    }

    &.grey {
        background-color: @vouchr-color-dark-grey;
        color: white;
    }

    &.light-grey {
        background-color: @vouchr-color-light-grey;
        color: white;
    }

    &.orange {
        background-color: @vouchr-color-orange;
        color: white;
    }

    &.btn-vouchr {
        line-height: 44px;
        height: 44px;
        text-transform: uppercase;
        padding-top: 0px;
        padding-bottom: 0px;
        font-weight: 800;
        text-align: center;
        vertical-align: middle;

        img {
            vertical-align: middle;
        }

        span.middle-helper {
            vertical-align: middle;
        }

        &.vouchr-cta {
            width: 169px;

            @media(max-width: @screen-md-max) {
                width: 119px;
            }

            @media(max-width: @screen-sm-max) {
                width: 129px;
            }
        }

        span.glyphicon, span.icon {
            margin-left: 5px;
        }

        .icon {
            fill: white;
            width: 22px;
            height: 22px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;

            use > svg {
                fill: white;
            }
        }

        &.btn-login {
            padding: 0px 20px 0px 20px;
            height: 44px;
            position: relative;
            font-size: 18px;

            span {
                vertical-align: middle;
            }

            svg {
                height: 25px;
                fill: white;
                position: absolute;
                top: 7.5px;
                right: 15px;
            }
        }
    }

    &.btn-vouchr-select {
        width: 100%;
        height: 44px;
        line-height: 44px;
        vertical-align: middle;
        text-align: left;
        background-color: white;
        border: 1px solid @vouchr-color-grey-border;
        padding: 0px 45px 0px 10px;
        color: black;

        .icon {
            width: 25px;
            height: 25px;
            position: absolute;
            right: 10px;
            margin-top: 8.5px;
            fill: @vouchr-color-orange;

            use > svg {
                fill: @vouchr-color-orange;
            }
        }
    }

    &.btn-search {
        border-radius: 0px;
        background-color: @vouchr-color-orange;
        padding: 0px 6px;
        height: 44px;
        width: 44px;

        .icon {
            position: absolute;
            top: 10.5px;
            left: 10.5px;
            width: 21px;
            height: 21px;
        }
    }

    &.btn-follow {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        font-weight: 800;
        height: 44px;
        width: 100%;
        background-color: @vouchr-color-dark-grey;
        color: white;
        padding: 0px;
        line-height: 44px;
        vertical-align: middle;
        font-size: 18px;

        &:after {
            content: 'Follow this brand';

            @media(max-width: @screen-md-max) {
                content: 'Follow';
            }

            @media(max-width: @screen-xs-max) {
                content: 'Follow';
            }
        }

        @media(max-width: @screen-xs-max) {
            float: left;
            width: 100%;
            margin-bottom: 10px;
        }

        &.following {
            background-color: @vouchr-color-orange;

            &:after {
                content: 'Following';
            }
        }

        .icon {
            display: inline-block;
            height: 20px;
            vertical-align: middle;
            margin-top: -3px;
            margin-right: 5px;
        }

        &.loading {
            background-image: url(../images/loading_grey_white.gif);
            background-position: center center;
            background-repeat: no-repeat;
            width: inherit;
            height: 44px;
            padding: 0;
            background-color: transparent;
            // margin-top: -4px;

            .icon {
                display: none;
            }

            &.white-on-grey {
                background-image: url(../images/loading_white_grey.gif);
                background-color: @vouchr-color-dark-grey;
            }

             &.white-on-orange {
                background-image: url(../images/loading_white_orange.gif);
            }

             &.white-on-yellow {
                background-image: url(../images/loading_white_yellow.gif);
            }

            &:after {
                content: '';
            }

            &.following {
                &:after {
                    content: '';
                }
            }
        }
    }

    &.btn-view-vouchr {
        text-align: center;
        background-color: @vouchr-color-babypoo;
        width: 100%;
        color: white;
        font-weight: 800;
    }

    &.btn-delete-vouchr {
        text-align: center;
        background-color: @vouchr-color-orange;
        // width: 100%;
        color: white;
        font-weight: 800;
        height: 44px;

        .icon {
            height: 30px;
            width: 25px;
        }
    }

    &.btn-vouchr-load-more {
        height: 44px;
        width: 100%;
        font-weight: 800;
        font-size: 17px;
        line-height: 44px;
        padding: 0px;
        background-color: white;
        color: @vouchr-color-orange;
        text-align: center;
        cursor: pointer;

        .round-box(7px);
        .shadow();

        @media(max-width: @screen-xs-max) {
            width: 248px;
            display: block;
            margin: 0 auto;
        }

        img {
        }
    }

    &.btn-login-cta {
        @cta-height: 78px;
        @cta-icon-height: 32px;

        position: relative;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 216px;
        height: @cta-height;
        line-height: @cta-height;
        padding: 0px;
        color: black;
        vertical-align: middle;
        text-align: left;
        padding-left: @cta-icon-height + 20px;
        font-size: 17px;

        .icon {
            fill: black;
            position: absolute;
            height: @cta-icon-height;
            width: @cta-icon-height;
            top: 50%;
            left: 10px;
            margin-top: (@cta-icon-height / 2) * -1;

            use > svg {
                fill: black;
            }
        }

        &.facebook-cta .icon {
            fill: @icon-color-facebook;

            use > svg {
               fill: @icon-color-facebook;
            }
        }
    }

    &.btn-clear {
        background-color: none;
        border: none;
    }

    &.btn-search-header {
        background-color: initial;
        border: none;
        padding: 2px 12px 0px 12px;

        span {
            color: white;
            font-weight: 800;
        }
    }

    &.btn-white-hollow {
        border: 1px solid white;
        background-color: initial;
    }
}