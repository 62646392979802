.round-box(@corner-width: @vouchr-round-corner) {
    border-radius: @corner-width;
}

.round-top-box(@corner-width: @vouchr-round-corner) {
    border-top-right-radius: @corner-width;
    border-top-left-radius: @corner-width;
}

.round-bottom-box(@corner-width: @vouchr-round-corner) {
    border-bottom-left-radius: @corner-width;
    border-bottom-right-radius: @corner-width;
}

.round-left-box(@corner-width: @vouchr-round-corner) {
    border-bottom-left-radius: @corner-width;
    border-top-left-radius: @corner-width;
}

.round-right-box(@corner-width: @vouchr-round-corner) {
    border-bottom-right-radius: @corner-width;
    border-top-right-radius: @corner-width;
}

.shadow(@distance: 1px, @dithering: 2px, @color: #e1e1e1) {
    box-shadow: @distance @distance @dithering @color;
    -moz-box-shadow: @distance @distance @dithering @color;
    -webkit-box-shadow: @distance @distance @dithering @color;
}

.no-appearance() {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.brand-column() {
    width: 227px;
    margin-left: 20px;
    float: right;
}

.category-column() {
    width: 227px;
    margin-left: 20px;
    float: right;
}