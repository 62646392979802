.coupon {
    background: transparent url('../images/vouchr_sleeve.png') no-repeat left top;
    background-size: 100%;
    width: @coupon-width;
    height: @coupon-height;
    display: inline-block;
    margin: 4px;
    -webkit-font-smoothing: subpixel-antialiased;

    .content {
        padding-top: 10px;
        text-align: left;
        position: relative;

        .info {
            width: 206px;
            height: 98px;
            margin: 0px auto;
            padding-bottom: 10px;
            border-radius: 0px 0px 7px 7px;

            h1, h2, h3, h4 {
                color: #FFF;
                font-weight: normal;
                margin: 0px;
                text-shadow: 0px 1px 0px #932D19;
                text-transform: uppercase;
            }

            h1 {
                font-size: 14px;
                padding: 6px 8px 0px 8px;
                line-height: 100%;
            }

            h2 {
                font-size: 11.5px;
                padding: 4px 8px 0px 8px;
            }

            h3 {
                font-family: Arial;
                font-size: 9.5px;
                font-weight: bold;
                padding: 0px 8px 0px 8px;

                &.discount {
                    font-size: 16px;
                    margin: 3px 0;
                }
            }

            h4 {
                font-family: Arial;
                font-size: 8.5px;
                padding: 1px 8px 0px 8px;
                text-transform: none;
                color: #ffffff;
                width: 208px;
                line-height: 100%;
            }
        }

        .addbtn {
            position: absolute;
            // margin-top: 123px;
            // margin-left: 173px;
            // margin: 0;
            right: 8px;
            top: 140px;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
        }

        .offers {
            position: absolute;
            margin-top: 178px;
            margin-left: 188px;
            width: 50px;
            text-align: center;

            h1, h2 {
                margin: 0;
                color: #FFF;
                font-weight: normal;
            }
        }
    }

    .pocketfront {
        position: absolute;
        margin: 117px 5px 0px 5px;
    }

    .proimage {
        width: @coupon-width - 19px;
        height: 115px;
        margin: 0px auto;
        border-radius: 15px 15px 0px 0px;

        img {
            border-radius: 10px 10px 0px 0px;
            width: @coupon-width - 19px;
            height: 113px;
        }
    }
}

.vouchr-list {
    margin-top: 20px;
}

.vouchr-list-no-directive {
    margin-top: 20px;
}

.vouchr-list-container {
    margin-bottom: 20px;

    .brand-container {
        height: 225px;
        background-color: white;
        .shadow();
        .round-box();
        .brand-column();

        .logo-container {
            height: 160px;
            line-height: 160px;
            text-align: center;
            vertical-align: middle;

            img {
                display: inline-block;
                width: auto;
                height: auto;
                max-width: 227px;
                max-height: 150px;
            }
        }

        .followers {
            text-align: center;
            font-weight: 800;
            color: @vouchr-color-followers;
            border-top: 1px solid @vouchr-color-grey-border;
            // border-right: 1px solid @vouchr-color-grey-border;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 0;
            width: 100%;
            float: left;
            font-size: 18px;
        }
    }

    .vouchr-container {
        background-color: white;
        overflow: hidden;
        width: auto;
        position: relative;
        .shadow();
        .round-right-box();

        .coupon {
            margin-left: 0px;
            margin-right: 20px;
            margin-bottom: 0px;
            margin-top: 0px;
            float: left;
        }

        .vouchr-list-content {
            overflow: hidden;
            padding-right: 20px;

            .heading {
                font-weight: 800;
                color: @vouchr-color-orange;
                font-size: 22px;
                margin-bottom: 0px;
            }

            .sub-heading {
                font-weight: 800;
                color: black;
                font-size: 16px;
                margin-top: 0px;
            }

            .vouchr-description {
                font-family: 'vagroundedlight';
                font-weight: 200;
                text-align: left;
                font-size: 16px;

                @media(max-width: @screen-md-max) {
                    font-size: 14px;
                }
            }
        }

        .btn-view-vouchr {
            position: absolute;
            width: 224px;
            height: 44px;
            bottom: 10px;
            right: 20px;
            padding: 10px 10px;

            @media(max-width: @screen-md-max) {
                width: 184px;
            }

            @media(max-width: @screen-sm-max) {
                width: 208px;
            }

            .view-offer-vouchr-text {
                display: inline;
            }
        }

        .btn-delete-vouchr {
            position: absolute;
            bottom: 10px;
            right: 20px;
            width: 50px;
            display: none;
        }
    }
}


@media(max-width: @screen-xs-max) {
    .coupon {
        .content {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .vouchr-list {
        width: 100%;
        margin: 20px auto;
    }

    .vouchr-list-no-directive {
        width: 100%;
        margin: 20px auto;
    }

    .vouchr-list-container {
        background-color: @vouchr-color-grey-border;
        padding: 10px;
        margin: 20px 20px;
        border-radius: 5px;

        .brand-container {
            height: 133px;
            width: 226px;
            float: none;
            margin: 0px auto 10px;

            .logo-container {
                width: 100%;
                height: 88px;
                float: left;

                img {
                    // height: 88px;
                    height: 58px;
                }
            }

            .followers {
                border: 0;
                border-top: 1px solid @vouchr-color-grey-border;
                margin: 0;
                width: 100%;
                float: left;
                padding: 12px 3px;

                &.vouchr-num-items {
                    border-top: 1px solid @vouchr-color-grey-border;
                }
            }
        }

        .vouchr-container {
            width: 226px;
            background-color: transparent;
            margin: 0 auto;

            .coupon {
                margin-right: 0;
                height: 280px;
            }

            .btn-view-vouchr {
                right: 0px;
                width: 224px;
                bottom: 0;
            }

            .btn-delete-vouchr {

            }
        }
    }
}
