@font-face {
    font-family: 'vagroundedbold';
    src: url('../fonts/18_vag_rounded_bold___07390-webfont.eot');
    src: url('../fonts/18_vag_rounded_bold___07390-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/18_vag_rounded_bold___07390-webfont.woff') format('woff'),
    url('../fonts/18_vag_rounded_bold___07390-webfont.ttf') format('truetype'),
    url('../fonts/18_vag_rounded_bold___07390-webfont.svg#vagroundedbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vagroundedlight';
    src: url('../fonts/18_vag_rounded_light___02390-webfont.eot');
    src: url('../fonts/18_vag_rounded_light___02390-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/18_vag_rounded_light___02390-webfont.woff') format('woff'),
    url('../fonts/18_vag_rounded_light___02390-webfont.ttf') format('truetype'),
    url('../fonts/18_vag_rounded_light___02390-webfont.svg#vagroundedlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vagrounded';
    src: url('../fonts/18_vag_rounded_light___02390-webfont.eot');
    src: url('../fonts/18_vag_rounded_light___02390-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/18_vag_rounded_light___02390-webfont.woff') format('woff'),
    url('../fonts/18_vag_rounded_light___02390-webfont.ttf') format('truetype'),
    url('../fonts/18_vag_rounded_light___02390-webfont.svg#vagroundedlight') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'vagrounded';
    src: url('../fonts/18_vag_rounded_bold___07390-webfont.eot');
    src: url('../fonts/18_vag_rounded_bold___07390-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/18_vag_rounded_bold___07390-webfont.woff') format('woff'),
    url('../fonts/18_vag_rounded_bold___07390-webfont.ttf') format('truetype'),
    url('../fonts/18_vag_rounded_bold___07390-webfont.svg#vagroundedbold') format('svg');
    font-weight: 700;
    font-style: normal;
}