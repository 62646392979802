body.thank-you {
    main {
        h1.main-title {
            text-align: center;
            margin-top: 0px;
            font-size: 54px;
        }

        h2.sub-title {
            font-size: 22.5px;
            text-align: center;
            margin-top: 31px;
        }

        p.description {
            text-align: center;
            font-family: 'vagroundedlight';
            font-weight: 200;
            font-size: 17.5px;

            a {
                color: @vouchr-color-orange;
            }
        }

        section.main-content {
            padding-top: 45px;
        }
    }
    
    .vouchr-list {
        margin-top: 0;

        .vouchr-list-container {
            margin: 0;

            .brand-container {
                height: 270px;
                background-color: white;
                box-shadow: 1px 1px 2px #e1e1e1;
                -moz-box-shadow: 1px 1px 2px #e1e1e1;
                -webkit-box-shadow: 1px 1px 2px #e1e1e1;
                border-radius: 5px;
                width: 100%;
                margin: 0;
                margin-top: 20px;
                float: left;
            }
        }
    }
}

@media(max-width: @screen-md-max) {
    body.thank-you {
    
    }
}

@media(max-width: @screen-sm-max) {
    body.thank-you {

    }
}

@media(max-width: @screen-xs-max) {
    body.thank-you {
        .vouchr-list {
            .vouchr-list-container {
                background-color: #e1e1e1;
                padding: 10px;
                margin: 20px 0;
                border-radius: 0px;
                .clearfix();

                .brand-container {
                    height: 182px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
