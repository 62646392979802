@header-height: 60px;
@nav-button-top-margin: 7px;
@nav-button-height: 44px;

header {
    height: @header-height;
    background-color: @vouchr-color-orange;

    .container {
    }

    .col-md-12 {
        position: initial;
    }

    .dropdown.main-dropdown {
        position: initial;

        > .dropdown-toggle {
            margin-top: @nav-button-top-margin;
            height: @nav-button-height;
            font-size: 16px;

            > span {
                margin-left: 15px;
            }
        }

        ul.dropdown-menu {
            list-style-type: none;
            width: 300px;
            padding: 5px 0px;
            margin-top: 8px;
            background-color: white;
            position: absolute;
            top: 51px;
            margin-left: 50%;
            left: 261px;

            @media(max-width: @screen-md-max) {
                left: 161px;
            }

            @media(max-width: @screen-sm-max) {
                left: 51px;
            }

            @media(max-width: @screen-xs-max) {
                left: 0px;
                width: ~"calc(100% + 1px)";
                margin-left: 0px;
            }

            .round-box(7px);

            @menu-item-height: 44px;

            menuitem {
                display: list-item;
                height: @menu-item-height;
                padding: 0px 20px;

                &:not(:last-child) {
                    a {
                        border-bottom: 1px solid #ccc;
                    }
                }

                &:hover {
                    background-color: #eee;
                }

                a {
                    display: block;
                    height: @menu-item-height;
                    width: 100%;
                    line-height: @menu-item-height;
                    width: 100%;
                    position: relative;
                    color: black;
                    font-size: 18px;
                    font-weight: 400;

                    .icon {
                        width: 20px;
                        height: 20px;
                        fill: @vouchr-color-orange;
                        position: absolute;
                        right: 20px;
                        top: 10px;

                        use > svg {
                           fill: @vouchr-color-orange;
                        }
                    }
                }
            }
        }

        &.open {
            .avatar-icon {
                margin-left: -49px;
            }

            nav {
                .dropdown-toggle {
                    &.btn.btn-vouchr .icon {
                        &.avatar-icon {
                            margin-left: -50px;
                        }
                    }
                }
            }
        }

    }

    .logo {
        height: 100%;
        padding-top: 10px;
        display: inline-block;
        float: left;
    }

    .btn-login {
        margin-top: 7px;
    }

    nav {
        height: 60px;
        line-height: 60px;
        z-index: 9001;
        float: left;
        padding-left: 50px;
        width: ~"calc(100% - 125px)";

        @media(max-width: @screen-sm-max) {
            padding-left: 20px;
        }

        @media(max-width: @screen-xs-max) {
            padding-left: 0px;
        }

        .dropdown-toggle {
            &.btn.btn-vouchr .icon {
                margin-top: -2px;
                margin-left: 1px;
                margin-right: 0px;

                &.avatar-icon {
                }
            }
        }

        menuitem {
            padding: 0px 10px;
            font-size: 18px;

            @media(max-width: @screen-sm-max) {
                padding: 0px 5px;

                &.menu-item-or {
                    padding: 0px 0px;
                }
            }

            &.extra-pad {
                padding: 0px 17px 0px 15px;
            }

            &:first-child {
                padding-right: 0px;
            }
        }

        a {
            color: white;
            font-weight: 800;

            &:hover {
                color: white;
            }
        }

        @nav-icon-size: 20px;

        .icon-hide-container {
            width: @nav-icon-size;
            height: @nav-icon-size;
            overflow: hidden;
            position: absolute;
            top: 20px;
            right: 10px;

            .icon {
                width: @nav-icon-size;
                height: @nav-icon-size;

                &.close-icon {
                }

                &.avatar-icon {
                }
            }
        }
    }
}

.search-form {
    .search-toggle {
        height: @nav-button-height;
        margin-top: @nav-button-top-margin;
        line-height: 26px;
        font-size: 18px;

        .search-icon-container {
            float: left;
            width: 25px;
            height: 25px;
            overflow: hidden;
            vertical-align: middle;
            margin-right: 15px;

            @media(max-width: @screen-xs-max) {
                margin-right: 0px;
                margin-top: -2px;
            }

            .icon {
                float: left;
                clear: both;
            }
        }

        span {
            vertical-align: middle;

            &.search-text {
                display: inline;
            }

            &.search-text-close {
                display: none;
            }
        }
    }

    &.dropdown {
        position: initial;
    }

    form.dropdown-menu {
        width: 400px;
        padding: 0px;
        margin-top: 8px;
        position: absolute;
        top: 51px;
        margin-left: 50%;
        left: 161px;

        @media(max-width: @screen-md-max) {
            left: 61px;
        }

        @media(max-width: @screen-sm-max) {
            left: -49px;
        }

        @media(max-width: @screen-xs-max) {
            left: 0px;
            margin-left: 0px;
            width: ~"calc(100% + 1px)";
        }

        &.results-open {
            .form-group {
                border-bottom: 1px solid #ccc;
                .round-bottom-box(0px);

                .input-group {
                    button {
                        .round-bottom-box(0px);
                    }
                }
            }
        }

        .round-box(7px);

        .form-group {
            width: 100%;
            margin-bottom: 0px;

            .input-group {
                width: 100%;

                .form-control:focus {
                    z-index: initial;
                }

                input {
                    border: 0;
                    outline: none;
                    box-shadow: none;
                }

                button {
                    height: 44px;
                    width: 49px;
                    background-color: @vouchr-color-babypoo;
                    padding: 0px;
                }
            }
        }
    }

    .results-list {
        list-style-type: none;
        max-height: ~"calc(100vh - 110px)";
        overflow-y: scroll;
        padding: 3px 0px 10px;

        li {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: initial;
            padding: 0px 20px;

            &:hover {
                background-color: #eee;
            }

            a {
                padding: 5px 0px;
                color: black;
                display: block;
                font-weight: 400;
                font-size: 18px;
                height: @nav-button-height;
                line-height: @nav-button-height - 6px;
            }

            &:not(:last-child) {
                a {
                    border-bottom: 1px solid #ddd;
                }
            }
        }
    }

    &.open {
        .search-toggle {
            background-color: @vouchr-color-babypoo;

            .search-icon-container {
                .search-icon {
                    margin-top: -25px;
                }
            }

            span {
                &.search-text {
                    display: none;
                }

                &.search-text-close {
                    display: inline;
                }
            }
        }
    }
}