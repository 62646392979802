body.vouchr-list-page {
    .vouchr-list-header {

        .vouchr-list-content {
            min-height: 177px;
            background-color: white;
            overflow: hidden;
            padding: 15px 20px;

            .shadow();

            h1 {
                margin: 0px;
                font-size: 38px;
            }

            h2 {
                font-size: 22px;
                margin: 12px 0px 13px 0px;
            }
        }
    }
    .vouchr-list {
        &.vouchr-listing {
            background: #fff;

            .vouchr-list-container {
                background-color: #fff;
                padding: 0;
                margin: 0;
                border-radius: 0;

                &:last-child {
                    .vouchr-divider {
                        border-top: 0px solid #dfdfdf;
                    }
                }


                .vouchr-title {
                    color: #313131;
                    font-size: 17px;
                    line-height: 20px;
                }

                .vouchr-description {
                    color: #313131;
                    font-size: 15px;
                    line-height: 19px;
                }

                .vouchr-list-link {
                    display: block;
                    margin-top: 20px;
                    color: @vouchr-color-orange;
                    font-size: 17px;
                    line-height: 20px;
                    font-weight: 700;
                    text-decoration: none;
                    outline: 0;
                    cursor: pointer;
                }
                .vouchr-list-view-link {
                    display: block;
                    margin-top: 20px;
                    color: #d19b02;
                    font-size: 17px;
                    line-height: 18px;
                    text-decoration: none;
                    outline: 0;
                    cursor: pointer;
                    font-weight: 700;
                }

                .vouchr-divider {
                    margin-top: 10px;
                    border-top: 1px solid #dfdfdf;
                }
            }
        }

    }
}

@media(max-width: @screen-xs-max) {

}