body {
	.breadcrumb-container {
    	background-color: #f5f5f5;

	    .breadcrumb {
	    	margin-bottom: 0;
	    	padding-top: 20px;
	    	border-radius: 0px;

	    	a { 
	    		font-size: 18px;
	    		color: #333;
	    		font-weight: 800;
	    		cursor: pointer;
	    		text-decoration: none;
	    	}
	        
	        li {
	        	color: #cb5e27 !important;
	        	font-size: 18px;
	        	font-weight: 800;
	        }
	    }
	}
}

@media(max-width: @screen-xs-max) {
	body {
	    .breadcrumb-container {
		    .breadcrumb {
				display: none;

		    	a { 
		    		font-size: 14px;
		    	}
		        
		        li {
		        	font-size: 14px;
		        }
		    }
		}
	}
}