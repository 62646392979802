body.contactus {

    main {
        h1.main-title {
            text-align: center;
            margin-top: 0px;
            font-size: 54px;
        }

        section.main-content {
            padding-top: 45px;

            .btn-save-changes {
                background-color: @vouchr-color-babypoo;
                color: white;
                padding: 8px 29px;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 16px;
                margin-left: 15px;

                &:hover {
                    color: @vouchr-color-orange;
                }
            }

            .form-inline {
                .form-group {
                    margin: 10px 0;

                    label {
                        width: 100px;
                        color: @vouchr-color-dark-grey;
                    }
                }

                .checkbox {
                    margin: 35px 0 50px 15px;
                    display: block;
                    color: @vouchr-color-dark-grey;

                    label {
                        width: 100%;

                        .label-text {
                            display: inline-block;
                            width: 90%;
                            padding-left: 20px;
                        }
                    }
                }

                input[type="text"], input[type="password"], input[type="email"] {
                    height: 40px;
                    padding: 3px 6px;
                    .round-box();
                    border: none;
                    .shadow();
                    width: 440px !important;
                    color: @vouchr-color-dark-grey;

                    @media(max-width: @screen-xs-max) {
                        width: 100% !important;
                    }
                }

                textarea {
                    width: 440px !important;
                    height: 150px !important;

                    @media(max-width: @screen-xs-max) {
                        width: 100% !important;
                    }
                }

                .styled-select.arrow-down {
                    width: 440px !important;

                    @media(max-width: @screen-xs-max) {
                        width: 100% !important;
                    }
                }

                input[type='checkbox'] {
                    .no-appearance();
                    width: 20px;
                    height: 20px;
                    background: white;
                    .round-box();
                    outline: 0;
                    .shadow();
                    vertical-align: text-bottom;

                    @media(max-width: @screen-sm-max) {
                        vertical-align: top;
                    }

                    &:focus {
                        outline: 0;
                    }

                    &:checked {
                        background: @vouchr-color-orange;
                        outline: 0;
                    }
                }
            }
        }

        .col-head {
            margin-bottom: 10px;
        }

        .divider {
            width: 100%;
            border-color: @vouchr-color-grey-border;
            margin: 35px 15px;
        }

        .terms-links {
            margin-left: 0px;

            a {
                color: @vouchr-color-orange;
                text-decoration: underline;
                font-size: 14px;
            }

            .separator {
                margin: 0px 20px -3px 5px;
                display: inline-block;
                border-right: 1px solid black;
                width: 20px;
                height: 15px;
            }
        }
    }
}

