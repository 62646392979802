body.category-list {
    .category-list-header {
        &:last-child {
            margin: 40px auto;
        }

        .category-list-content {
            min-height: 177px;
            background-color: white;
            overflow: hidden;
            padding: 15px 20px;

            .shadow();

            h1 {
                margin: 0px;
                font-size: 38px;
            }

            h2 {
                font-size: 22px;
                margin: 12px 0px 13px 0px;
            }

            .category-list-alphabet {
                margin-top: 20px;
                padding-top: 10px;
                border-top: 1px solid #dfdfdf;

                .category-list-alphabet__letter {
                    padding: 0 10px;
                    display: inline-block;

                    a {
                        text-decoration: none;
                        color: #747474;
                    }
                }
            }

            &.category-list-margin {

                &:first-child {
                    margin-top: 0px;
                }

                margin-top: 40px;

                .category-list-link {
                    padding: 5px 0;

                    a {
                        text-decoration: none;
                        color: #313131;
                    }
                }
            }
        }
    }
}

@media(max-width: @screen-xs-max) {

}