.list-filter-form {
    .shadow();
    .round-top-box();

    .col-head {
        .icon {
            right: 15px;
            left: initial;

            &.rotated {
                transform: rotate(180deg);
            }
        }
    }

    form {
        padding: 20px;
        background-color: white;

        .form-group:last-child {
            margin-bottom: 0px;
        }

        .search-input-group {
            border: 1px solid @vouchr-color-grey-border;

            .form-control {
                border: none;
            }
        }

        .results-list {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1000;
            display: none;
            float: left;
            min-width: 160px;
            padding: 5px 0;
            margin: 2px 0 0;
            list-style: none;
            font-size: 14px;
            text-align: left;
            background-color: #fff;
            border: 1px solid #ccc;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            background-clip: padding-box;
            max-height: 300px;
            overflow: scroll;

            &.results-open {
                display: block;
            }

            li {
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: initial;
                padding: 0px 20px;

                &:hover {
                    background-color: #eee;
                }

                a {
                    padding: 5px 0px;
                    color: black;
                    display: block;
                    font-weight: 400;
                    font-size: 18px;
                    height: @nav-button-height;
                    line-height: @nav-button-height - 6px;
                }

                &:not(:last-child) {
                    a {
                        border-bottom: 1px solid #ddd;
                    }
                }
            }
        }
    }

    &.list-filter-form.scrolling {
        position: fixed;
        width: 262.5px;
        top: 50%;

        form {
            margin-bottom: 0;
        }
    }
}

input[type="text"], input[type="password"] {
    height: 44px;
    line-height: 44px;
    padding: 0px 12px;
}

.form-group {
    &.no-margin {
        margin: 0px;
    }
}

@media(max-width: @screen-xs-max) {
    .form-wrapper {
        margin-bottom: 1em;
    }
}