.svg-avatar-dims {
	width: 25px;
	height: 25px;
}

.svg-bin-dims {
	width: 24px;
	height: 24px;
}

.svg-brands-dims {
	width: 21px;
	height: 16px;
}

.svg-browse-arrow-dims {
	width: 2400px;
	height: 2400px;
}

.svg-carousel-left-dims {
	width: 3700px;
	height: 3700px;
}

.svg-carousel-right-dims {
	width: 3700px;
	height: 3700px;
}

.svg-carouselpager-active-dims {
	width: 1500px;
	height: 1500px;
}

.svg-carouselpager-inactive-dims {
	width: 1500px;
	height: 1500px;
}

.svg-close-dims {
	width: 20px;
	height: 20px;
}

.svg-close-naked-dims {
	width: 33px;
	height: 33px;
}

.svg-emailsignup-dims {
	width: 2500px;
	height: 2500px;
}

.svg-facebook-dims {
	width: 512px;
	height: 512px;
}

.svg-facebooklogo-dims {
	width: 2500px;
	height: 2500px;
}

.svg-features-dims {
	width: 2600px;
	height: 2600px;
}

.svg-floppy-dims {
	width: 22px;
	height: 22px;
}

.svg-follow-dims {
	width: 2500px;
	height: 2000px;
}

.svg-hamburger-dims {
	width: 39px;
	height: 32px;
}

.svg-key-dims {
	width: 23px;
	height: 23px;
}

.svg-login-dims {
	width: 2500px;
	height: 2500px;
}

.svg-logout-dims {
	width: 19px;
	height: 19px;
}

.svg-mail-dims {
	width: 512px;
	height: 512px;
}

.svg-save-dims {
	width: 1800px;
	height: 1800px;
}

.svg-search-dims {
	width: 2300px;
	height: 2400px;
}

.svg-settings-dims {
	width: 20px;
	height: 20px;
}

.svg-share-dims {
	width: 1800px;
	height: 2000px;
}

.svg-twitter-dims {
	width: 512px;
	height: 512px;
}

.svg-up-dims {
	width: 24px;
	height: 24px;
}

.svg-usenow-dims {
	width: 1600px;
	height: 1600px;
}

