.col-head {
    background-color: @vouchr-color-orange;
    color: white;
    height: @col-head-height;

    h1, h2, h3, h4 {
        margin: 0px;
        line-height: @col-head-height;
        padding-left: 20px;
        font-size: 18px;
        position: relative;

        @media(max-width: @screen-xs-max) {
            font-size: 16px;
        }

        &.icon-padded {
            padding-left: 52px;

            @media(max-width: @screen-xs-max) {
                padding-left: 42px;
            }
        }
    }

    .icon {
        position: absolute;
        left: 15px;
        top: (@col-head-height - 25px) / 2;
        width: 25px;
        height: 25px;

        @media(max-width: @screen-xs-max) {
            left: 12px;
        }
    }

    @header-border-radius: 5px;

    &.round-top {
        border-top-right-radius: @header-border-radius;
        border-top-left-radius: @header-border-radius;
    }

    &.round-bottom {
        border-bottom-right-radius: @header-border-radius;
        border-bottom-left-radius: @header-border-radius;
    }
}

.shadow-box {
    padding: 20px;
    background-color: white;

    .shadow();
    .round-box();
}