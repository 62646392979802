.modal.register {
    h1 {
        margin-top: 0px;
    }

    form {
        margin-bottom: 0px;
    }

    .alert {
	    padding: 5px 15px;
	    margin-top: 5px;
	    margin-bottom: 20px;
	    border: 1px solid transparent;
	    border-radius: 4px;
	}

	.input-group-btn {
		button.btn.btn-default {
			padding: 14px 12px;
		}
	}
}

.modal.privacy {
	.privacy-content {
		max-height: 678px;
		overflow: scroll;

		li {
			margin-left: 20px;
		}
	}
}

.modal.terms {
	.terms-content {
		max-height: 678px;
		overflow: scroll;

		p {
			span {
				padding-left: 20px;
			    display: inline-block;
			    padding-top: 5px;
			}
		}

		li {
			margin-left: 20px;
		}
	}
}