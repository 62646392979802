footer {
    background-color: @vouchr-color-footer-background;
    padding-top: 30px;
    padding-bottom: 20px;
    color: white;

    a {
        color: white;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }

    .row {
        margin-bottom: 30px;
    }

    ul {
        list-style-type: none;

        li {
            float: left;
            text-align: left;

            &.col-md-2, .col-sm-3 .col-xs-6 {
                padding: 2px 0px 2px 0px;
            }

            @media(max-width: @screen-xs-max) {
                text-align: center;
            }
        }
    }

    h4 {
        marign-top: 0px;

        @media(max-width: @screen-xs-max) {
            text-align: center;
        }
    }
}