.modal.share {
    .modal-dialog {
        width: 708px;
    }

    .modal-body {
        padding: 20px;
    }

    .share-content {
        // margin-top: 20px;

        .share-content-icons {
            padding: 15px 0;
        }

        .btn-share {

            &.btn-share-facebook {
                svg.icon {
                    fill: @icon-color-facebook;
                    width: 50px;
                    height: 50px;

                    use > svg {
                       fill: @icon-color-facebook;
                    }
                }
            }

            &.btn-share-twitter {
                svg.icon {
                    fill: @icon-color-twitter;
                    width: 50px;
                    height: 50px;

                    use > svg {
                       fill: @icon-color-twitter;
                    }
                }
            }

            &.btn-share-mail {
                background-color: @icon-color-mail;
                padding: 4px 6px;
                margin-top: 8px;
                margin-left: 6px;

                svg.icon {
                    fill: white;
                    width: 40px;
                    height: 40px;

                    use > svg {
                       fill: white;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .modal-dialog {
            width: inherit;
        }
    }

    h1 {
        margin-top: 0px;
    }

    p {
        margin-bottom: 0px;
    }
}