.highlight {
    &.orange {
        color: @vouchr-color-orange !important;
    }
}

body {
    font-family: 'vagrounded';
    background-color: @vouchr-color-footer-background;
    -webkit-font-smoothing: antialiased;

    > svg {
        display: none;
    }

    main {
        padding-bottom: 140px;
        padding-top: 40px;
        background-color: @vouchr-color-content-background;
    }
}

h1, h2, h3, h4, h5 {
    font-weight: 800;
}

svg {
    fill: white;
    width: 25px;
    height: 25px;
}

.bold {
    font-weight: 800;
}

.white {
    color: #ffffff;
}

.dropdown {
    .dropdown-menu {
        width: 100%;
        max-height: 300px;
        overflow: scroll;

        a {
            cursor: pointer;
        }
    }
}

.middle-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.middle-helper-brand {
    display: inline-block;
    height: 165px;
    vertical-align: middle;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.show-loading {
    display: none;
    background-image: url(../images/loading_grey_white.gif);
    background-position: center center;
    background-repeat: no-repeat;
    width: 42px;
    height: 42px;
    padding: 0;
    background-color: transparent;
    // margin-top: -4px;

    &.white-on-grey {
        background-image: url(../images/loading_white_grey.gif);
        background-color: @vouchr-color-dark-grey;
    }

     &.white-on-orange {
        background-image: url(../images/loading_white_orange.gif);
    }

     &.white-on-yellow {
        background-image: url(../images/loading_white_yellow.gif);
    }
}

.loading {
    .hide-loading {
        display: none;
    }

    .show-loading {
        display: inline-block;
    }
}