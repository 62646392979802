@select-height: 44px;
@select-desktop-width: 100%;

.styled-select {
    overflow: hidden;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    border: 1px solid @vouchr-color-form-control-border;
    .round-box();

    select {
        background: transparent;
        border: none;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        font-size: 14px;
        height: @select-height;
        padding: 5px;
        width: 100%;
        .no-appearance();
        z-index: 2;
        position:relative;
        transition: background 0.4s ease;
        color: @vouchr-color-dark-grey;

        &:hover {
            cursor: pointer;
            outline: 0;
        }

        &:focus {
            cursor: pointer;
            outline: 0;
        }

        option {
            display: block;
            line-height: 1.5;
            font-size: 1em;
            border: none;
        }
    }

    &.arrow-down {
        background: white no-repeat right center;
        height: @select-height;
        width: 240px;

        @media(max-width: @screen-sm-max) {
            width: 210px;
        }

        @media(max-width: @screen-xs-max) {
            width: 100%;
        }

        select {
            border: 1px solid #fff;
            font-size: 16px;
            height: @select-height;
            width: @select-desktop-width;
            padding-left: 10px;

            @media(max-width: @screen-sm-max) {
                width: 210px;
            }

            @media(max-width: @screen-xs-max) {
                width: 100%;
            }
        }

        svg.icon {
            position: absolute;
            right: 10px;
            top: 10px;
            fill: @vouchr-color-orange;
            z-index: 1;
            display: block;
            width: 22px;
            height: 22px;

            use > svg {
               fill: @vouchr-color-orange;
            }
        }
    }
}
